import {
  CheckIcon,
  EditIcon,
  ShareIcon,
  ViewIcon,
} from '@/react/componentAssets'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'
import { CopyIcon } from '@/react/componentAssets/CopyIcon'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import Highlighter from 'react-highlight-words'
import paperClip from '@/assets/icons/paper-clip.svg'
import { UISref } from '@components/UISref'

export const ViewRow = ({
  formData,
  index,
  handleChecked,
  selectedItems,
  setRefresh,
  isActionsAvailable,
  search,
  page,
}) => {
  const {
    form,
    project,
    user,
    fields,
    date_created,
    client_object_key,
    external_contacts,
    contains_files,
  } = formData

  const { external_contact } = external_contacts[0] || {}

  const { Api, CurrentUser, Notification } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const { stateService } = useRouter()

  const [hoveredField, setHoveredField] = useState(null)
  const [isLinkCopied, setLinkCopied] = useState(false)
  const [isCopyClicked, setCopyClicked] = useState(false)

  const handleCopyLink = () => {
    const currentURL = window.location.href
    const urlObject = new URL(currentURL)
    const host = urlObject.host

    navigator.clipboard.writeText(
      `https://${host}/share/report/${client_object_key}`,
    )

    setLinkCopied(true)
  }

  const selectedIds = useMemo(() => {
    return selectedItems.map((item) => item.id)
  }, [selectedItems])

  const handleCopyReport = async () => {
    try {
      const { data: fullFormData } = await Api.get('reports', {
        id: formData?.id,
        no_fields: true,
      })

      const { data: fieldsResponse } = await Api.get('report_fields', {
        deleted: false,
        report: formData?.id,
        page_size: 200,
      })

      if (fullFormData?.results) {
        const fullForm = fullFormData?.results[0]

        try {
          const { data } = await Api.post('reports', {
            application: fullForm?.application?.id,
            form: fullForm?.form?.id,
            project: fullForm?.project?.id,
            synchronised: fullForm?.synchronised,
            user: CurrentUser.getId(),
          })

          if (data) {
            await Api.post(`reports/${data?.id}/send_alerts`, {
              created: true,
            })

            if (fieldsResponse?.results) {
              const modifiedFields = await Promise.all(
                fieldsResponse?.results.map(async (field) => {
                  delete field.id

                  field.signatures = field.signatures.length
                    ? await Promise.all(
                        field?.signatures?.map(async (sign) => {
                          const { data: newSign } = await Api.post(
                            `image_upload/${sign.image.id}/copy`,
                          ).then(async ({ data: image }) => {
                            return await Api.Signatures.post({
                              image: image.id,
                              user: sign.user,
                              printed_name: sign.printed_name,
                            })
                          })
                          return newSign.id
                        }),
                      )
                    : []
                  field.sketches = field.sketches.length
                    ? await Promise.all(
                        field?.sketches?.map(async (sketch) => {
                          const { data: newSketch } = await Api.post(
                            `image_upload/${sketch.image.id}/copy`,
                          ).then(async ({ data: image }) => {
                            return await Api.post('sketches', {
                              image: image.id,
                              user: sketch.user,
                              printed_name: sketch.printed_name,
                            })
                          })
                          return newSketch.id
                        }),
                      )
                    : []
                  field.photos = field.photos.length
                    ? await Promise.all(
                        field?.photos?.map(async (ph) => {
                          const { data: newImage } = await Api.post(
                            `image_upload/${ph.id}/copy`,
                          )
                          return newImage.id
                        }),
                      )
                    : []
                  field.files = field.files.length
                    ? await Promise.all(
                        field?.files?.map(async (file) => {
                          const { data: newFile } = await Api.post(
                            `file_upload/${file.id}/copy`,
                          )
                          return newFile.id
                        }),
                      )
                    : []
                  field.multi_answers = field.multi_answers.length
                    ? field?.multi_answers?.map((answ) => answ?.id)
                    : []
                  field.map_snapshot = field?.map_snapshot?.hasOwnProperty('id')
                    ? field?.map_snapshot?.id
                    : null

                  field.report = data.id

                  return await field
                }),
              )

              await Api.post('report_fields', modifiedFields)
            }
          }
        } catch (error) {
          console.log(error)
        }
      }

      setCopyClicked(false)
      setRefresh(true)
    } catch (error) {
      console.error('Error during API requests:', error)
    }
  }

  return (
    <>
      <tr
        className={classNames('view__row', {
          dark: index % 2 === 0,
        })}
      >
        {isActionsAvailable ? (
          <td className="checkmark__td">
            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={selectedIds.includes(formData?.id)}
                onChange={() => {
                  handleChecked(formData)
                }}
              />
              <span className="checkmark">
                <ThinCheckmark color={variableColor} />
              </span>
            </label>
          </td>
        ) : (
          <></>
        )}
        <td
          className="title__td"
          onMouseOver={() => {
            setHoveredField({
              field__name: 'title__fullname',
              text: fields[0]?.text,
              max__length: 38,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <div className="title__cell__wrapper">
            {contains_files && <img src={paperClip} alt="" />}
            <p className="table__text title">
              <Highlighter
                highlightClassName={'highlighted__item'}
                searchWords={[search]}
                autoEscape={true}
                textToHighlight={fields[0]?.text || form?.name}
              />
            </p>
          </div>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'template__fullname',
              text: form?.name,
              max__length: 27,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="table__text template">
            <Highlighter
              highlightClassName={'highlighted__item'}
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={form?.name}
            />
          </p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'owner__fullname',
              text: external_contact
                ? `${external_contact?.first_name} ${external_contact?.last_name}`
                : `${user?.first_name} ${user?.last_name}`,
              max__length: 13,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="table__text owner">
            <Highlighter
              highlightClassName={'highlighted__item'}
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={
                external_contact
                  ? `${external_contact?.first_name} ${external_contact?.last_name}`
                  : `${user?.first_name} ${user?.last_name}`
              }
            />
          </p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'company__fullname',
              text: external_contact
                ? `${external_contact?.company_name}`
                : `${user?.company_name}`,
              max__length: 19,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="table__text company">
            <Highlighter
              highlightClassName={'highlighted__item'}
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={
                external_contact
                  ? `${external_contact?.company_name}`
                  : `${user?.company_name}`
              }
            />
          </p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'project__fullname',
              text: project?.name,
              max__length: 20,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="table__text project">
            <Highlighter
              highlightClassName={'highlighted__item'}
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={project?.name}
            />
          </p>
        </td>
        <td>
          <Highlighter
            highlightClassName={'highlighted__item'}
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={moment(date_created).format('MM/DD/YY')}
          />
        </td>
        <td className="icon__td">
          <CopyIcon
            color={variableColor}
            onClick={() => {
              setCopyClicked(true)
            }}
          />
          {isCopyClicked && (
            <ConfirmationModal
              handleClick={handleCopyReport}
              handleClose={() => {
                setCopyClicked(false)
              }}
              action={'Copy'}
              title={fields[0]?.text || form?.name}
            />
          )}
        </td>
        <td className="icon__td">
          <UISref
            to={'app.forms.reports_view'}
            params={{
              report: formData?.id,
              previousPageNumber: page,
              previousSearch: search,
            }}
          >
            <ViewIcon color={variableColor} />
          </UISref>
        </td>
        <td className="icon__td">
          <UISref
            to={'app.forms.reports_edit'}
            params={{
              report: formData?.id,
              previousPageNumber: page,
              previousSearch: search,
            }}
          >
            <EditIcon color={variableColor} />
          </UISref>
        </td>
        <td
          onClick={handleCopyLink}
          onMouseOut={() => {
            setLinkCopied(false)
          }}
          className="icon__td"
        >
          <ShareIcon
            height="30px"
            width="30px"
            color={variableColor}
            additionalStyles={{
              position: 'relative',
            }}
          />
          {isLinkCopied && (
            <div className="sucess__copy">
              <CheckIcon width="11" height="8" color="green" />
              <p className="copy__message">URL copied to clipboard</p>
            </div>
          )}
        </td>
      </tr>
      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </>
  )
}
