import { useAngularServices } from '@/react/components'
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'

export const SingleQuiz = ({ field, index, handleQuizAnswers, quizPassed }) => {
  const [selectedOption, setSelectedOption] = useState('')
  const { CurrentUser } = useAngularServices()
  const variableColor: string =
    CurrentUser.getClientSettings().web_primary_color
  const baseStyle = getBaseStyle(variableColor)

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  useEffect(() => {
    if (quizPassed) {
      const possibleAnswer = field?.answer_options?.find(
        (option) => option.user_answer === true,
      )
      setSelectedOption(possibleAnswer?.name)
    }
  }, [quizPassed])

  return (
    <div className="single__quiz" css={baseStyle}>
      <p className="quiz__text">
        {index + 1}. {field.name}
      </p>
      <form className="quiz__options">
        {field.answer_options.map((option) => (
          <label key={option.id} className="single__option">
            <div>
              <input
                type="radio"
                value={option.name}
                checked={selectedOption === option.name}
                onChange={(e) => {
                  handleOptionChange(e)
                  handleQuizAnswers({
                    answerId: option.id,
                    contendId: field.id,
                  })
                }}
                className="quiz__input"
              />
            </div>
            <p className="option__value">{option.name}</p>
          </label>
        ))}
      </form>
    </div>
  )
}

function getBaseStyle(variableColor) {
  return css({
    marginLeft: '15px',
    '.quiz__options': {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '10px',
      marginBottom: '10px',
    },
    '.single__option': {
      display: 'flex',
      gap: '7px',
      color: '#575757',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      letterSpacing: '-0.14px',
      marginLeft: '20px',
    },
    '.quiz__text': {
      fontSize: '14px',
      paddingRight: '15px',
    },
    '.option__value': {
      padding: 0,
      paddingRight: '15px',
    },
    '.quiz__input': {
      margin: 0,
      height: '16px',
      width: '16px',
      borderRadius: '16px',
      border: '0.5px solid #B1B1B1',
      background: '#FAFAFA',
      accentColor: variableColor,
      alignSelf: 'flex-start',
    },
  })
}
