import * as React from 'react'
import { TextInputStyle } from '@screens/components'

import upArrow from '/src/assets/icons/svg-gray-icons/small_gray_arrow_up.svg'
import downArrow from '/src/assets/icons/svg-gray-icons/small_gray_arrow_down.svg'
import { debounce } from 'lodash'
import classNames from 'classnames'

export const NumberInput = ({
  valueCallback,
  maxNumber = Infinity,
  arrowScaler = true,
  height = 50,
  width = 'auto',
  defaultValue = '',
  placeholder = '',
  waitTime = 0,
  maxWidth = '100%',
  fakeNumberInput = false,
  textInputDefaultValue = '',
  error = false,
}) => {
  const [oldNumber, setOldNumber] = React.useState<number>(
    Number(textInputDefaultValue) || defaultValue,
  )

  const variableStyle = {
    width: width,
    display: 'flex',
    maxWidth: maxWidth,
    input: {
      ...TextInputStyle,
      height: height,
      width: width,
      maxWidth: '100%',
    },
    '.scalerArrows': {
      marginLeft: -height / 2 - 2,
      width: height / 2 - 2,
      display: 'flex',
      flexDirection: 'column',
      padding: 2,
    },
    '.error__input': {
      border: '1px solid #C80404',
    },
    '.arrow': {
      height: height / 2 - 2,
      width: '100%',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      img: {
        height: 5,
        width: 9,
      },
    },
  }

  const inputRef = React.useRef()

  const debouncedOnChange = React.useMemo(
    () => debounce(valueCallback, waitTime),
    [valueCallback, waitTime],
  )

  function handleStepChange(step, minus) {
    if (minus) {
      if (fakeNumberInput) {
        if (Number(oldNumber) <= 10) {
          handleChangeText('0' + (Number(oldNumber) - step))
        } else {
          handleChangeText('' + (Number(oldNumber) - step))
        }
      } else {
        handleChange(Number(oldNumber) - step + '')
      }
      return
    }
    if (fakeNumberInput) {
      if (Number(oldNumber) >= 9) {
        handleChangeText('' + (Number(oldNumber) + step))
      } else {
        handleChangeText('0' + (Number(oldNumber) + step))
      }
    } else {
      handleChange(Number(oldNumber) + step + '')
    }
  }

  function handleChange(inputValue) {
    if (placeholder === 'MM' && inputValue >= 0) {
      if (inputValue.length <= 2) {
        inputRef.current.value = inputValue.replace(/[^0-9.-]/g, '')
        debouncedOnChange(inputValue.replace(/[^0-9.-]/g, ''))
        setOldNumber(inputValue.replace(/[^0-9.-]/g, ''))
      } else {
        inputRef.current.value = oldNumber
      }
    } else {
      inputRef.current.value = inputValue.replace(/[^0-9.-]/g, '')
      debouncedOnChange(inputValue.replace(/[^0-9.-]/g, ''))
      setOldNumber(inputValue.replace(/[^0-9.-]/g, ''))
    }
  }

  function handleChangeText(inputValue) {
    const value = inputValue.replace(/\D/g, '')
    if (value.length === 3) {
      inputRef.current.value = oldNumber
      return
    }
    if (Number(value) > maxNumber) {
      inputRef.current.value = oldNumber
      return
    }
    if (value === '00') {
      inputRef.current.value = '00'
      debouncedOnChange(0)
      setOldNumber(0)
      return
    }
    inputRef.current.value = value
    debouncedOnChange(value)
    setOldNumber(value)
  }

  return (
    <div css={variableStyle}>
      {fakeNumberInput ? (
        <input
          defaultValue={textInputDefaultValue || defaultValue}
          type="text"
          ref={inputRef}
          placeholder={placeholder}
          onChange={(value) => {
            handleChangeText(value.target.value)
          }}
          className={classNames({ error__input: error })}
        />
      ) : (
        <input
          defaultValue={defaultValue === 0 ? '' : defaultValue}
          type="text"
          ref={inputRef}
          placeholder={placeholder}
          onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
          onChange={(value) => {
            handleChange(value.target.value.replace(/[^0-9.-]/g, ''))
          }}
          className={classNames({ error__input: error })}
        />
      )}
      {!arrowScaler ? null : (
        <div className="scalerArrows">
          <div
            className="arrow"
            onClick={() => {
              handleStepChange(1)
            }}
          >
            <img src={upArrow} alt="/\" />
          </div>
          <div
            className="arrow"
            onClick={() => {
              handleStepChange(1, 'minus')
            }}
          >
            <img src={downArrow} alt="\/" />
          </div>
        </div>
      )}
    </div>
  )
}
