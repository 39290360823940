import { useRouter } from '@/react/hooks'
import { CreateEditQuestionComponent } from '../CreateEditQuestionPage/CreateEditQuestionComponent'
import { useAngularServices } from '@/react/components'

export const ObservationCustomQuestions = () => {
  const { stateService } = useRouter()
  const { Api, CurrentUser } = useAngularServices()

  const handleQuestionAdd = async (selectedCategory, editorContent) => {
    const { data: question } = await Api.post('questions', {
      category: selectedCategory?.id,
      name: editorContent,
    })
    try {
      await Api.post('client_app_questions', {
        application: stateService.params.app,
        client: CurrentUser.getClientId(),
        custom_categories: [selectedCategory?.id],
        is_active: true,
        question: question?.id,
      })
    } catch {
      console.log('Error during adding the question')
    }

    stateService.go('app.questions.my')
  }
  return (
    <CreateEditQuestionComponent
      title={'Add Question'}
      handleQuestionAdd={handleQuestionAdd}
    />
  )
}
