import { ReactWrapper } from '@components'

import { AdminHome } from './Home/AdminHome'
import { AdminTable } from './AdminTable/AdminTable'
import { ClientsTable } from './CliensTable/ClientsTable'
import { CreateEditApp } from './CreateEditApp/CreateEditApp'
import { SwitcherTables } from './SwitcherTables/SwitcherTables'
import {
  AppCathegories,
  AppClients,
  AppConfig,
  AppMobileForms,
  AppNews,
  AppProcedures,
  AppProcessFlow,
  AppQuestions,
  AppToolboxTalks,
  AppTraining,
} from './Apps'

export const adminHome = ReactWrapper(AdminHome, 'adminHome')
export const adminTable = ReactWrapper(AdminTable, 'adminTable')
export const clientsTable = ReactWrapper(ClientsTable, 'adminClientsTable')
export const adminCreateEditApp = ReactWrapper(
  CreateEditApp,
  'adminCreateEditApp',
)
export const adminSwitcherTables = ReactWrapper(
  SwitcherTables,
  'adminSwitcherTables',
)
export const adminAppCathegories = ReactWrapper(
  AppCathegories,
  'adminAppCathegories',
)
export const adminAppClients = ReactWrapper(AppClients, 'adminAppClients')
export const adminAppConfig = ReactWrapper(AppConfig, 'adminAppConfig')
export const adminAppMobileForms = ReactWrapper(
  AppMobileForms,
  'adminAppMobileForms',
)
export const adminAppNews = ReactWrapper(AppNews, 'adminAppNews')
export const adminAppProcedures = ReactWrapper(
  AppProcedures,
  'adminAppProcedures',
)
export const adminAppProcessFlow = ReactWrapper(
  AppProcessFlow,
  'adminAppProcessFlow',
)
export const adminAppQuestions = ReactWrapper(AppQuestions, 'adminAppQuestions')
export const adminAppToolboxTalks = ReactWrapper(
  AppToolboxTalks,
  'adminAppToolboxTalks',
)
export const adminAppTraining = ReactWrapper(AppTraining, 'adminAppTraining')
