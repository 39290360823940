import { makeLoadResolver, resolvers } from '../../app/routes/resolvers'

/** @ngInject */
export function mobileFormsConfig($stateProvider) {
  $stateProvider
    .state('app.forms.settings', {
      url: '/forms/settings?pageNumber&order&search&reverse',
      template: '<settings-page />',
      controller: function () {},
      resolve: resolvers.appResolver,
    })
    .state('app.forms.reports_create', {
      controller: function () {},
      template: '<create-edit-page />',
      url: '/forms/reports/create/:form/:project?previousPageNumber&previousOrder&previousReverse&previousSearch',
      resolve: {
        ...resolvers.appResolver,
        ...resolvers.writeOnlyViewResolver,
        ...resolvers.allowEditing,
        ...resolvers.PreviousStateResolver,
      },
    })
    .state('app.forms.reports_edit', {
      controller: function () {},
      template: '<create-edit-page />',
      url: '/forms/reports/:report/edit?previousPageNumber&previousOrder&previousReverse&previousSearch',
      resolve: {
        ...resolvers.appResolver,
        ...resolvers.writeOnlyViewResolver,
        ...resolvers.allowEditing,
        ...resolvers.PreviousStateResolver,
      },
    })
    .state('assigned.external_form', {
      controller: function () {},
      template: '<create-edit-page-external />',
      url: '/assigned/external_form/:client_object_key/router/:app_id/?:application_bundle_id&key=:external_user_id',
      params: {
        client_object_key: {
          value: '',
          dynamic: true,
        },
        app_id: {
          value: '',
          dynamic: true,
        },
        application_bundle_id: {
          value: '',
          dynamic: true,
        },
        external_user_id: {
          value: '',
          dynamic: true,
        },
      },
    })
    .state('app.forms.draft_reports_list', {
      template: '<mobile-form-draft-list/>',
      url: '/forms/draft_reports_list?pageNumber&order&reverse&search',
      resolve: resolvers.appResolver,
    })
    .state('app.forms.deleted_reports', {
      template: '<mobile-form-delete-list/>',
      url: '/forms/deleted_reports?pageNumber&order&reverse&search',
      resolve: resolvers.appResolver,
    })
    .state('app.forms.reports_export_list', {
      template: '<mobile-form-export-list/>',
      url: '/forms/reports_export_list?previousPageNumber&previousOrder&previousReverse&previousSearch&startDate&endDate&formId',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionDownloadReportDataOrCreateForm'),
      },
    })
    .state('app.forms.download_report_data', {
      template: '<mobile-form-download-data />',
      url: '/forms/download_report_data?pageNumber&order&reverse&search',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionDownloadReportDataOrCreateForm'),
      },
    })

    .state('app.forms.reports', {
      template: '<mobile-form-list-component></mobile-form-list-component>',
      url: '/forms/reports?pageNumber&order&reverse&search',
      resolve: resolvers.appResolver,
    })
    .state('app.forms.add_report', {
      template: '<add-mobile-reports-component></add-mobile-reports-component>',
      url: '/forms/add_report',
      resolve: resolvers.appResolver,
    })
}
