import appModule from '@appModule'
import { reactDemoConfig } from './react-demo'
import { safetyDataSheetsConfig } from './sds-pages-routes'
import { observationsConfig } from './observations-routes'
import { costCodesConfig } from './cost-codes-routes'
import { mobileFormsConfig } from './mobile-forms-routes'
import { timeCardRouteConfig } from './timecart'
import { loginPagesConfig } from './login-pages-routes'
import { trainingRouteConfig } from './trainings-routes'
import { toolboxTalksRouteConfig } from './toolbox-talks-routes'
import { adminCompanyConfig } from './admin-company'
import { clientAssessmentConfig } from './assessment-routes'
import { companyNewsConfig } from './company-news'
import { shareConfig } from './share-routes'
import { adminConfig } from './admin-pages'

appModule
  .config(adminConfig)
  .config(reactDemoConfig)
  .config(safetyDataSheetsConfig)
  .config(observationsConfig)
  .config(costCodesConfig)
  .config(mobileFormsConfig)
  .config(timeCardRouteConfig)
  .config(trainingRouteConfig)
  .config(loginPagesConfig)
  .config(toolboxTalksRouteConfig)
  .config(adminCompanyConfig)
  .config(clientAssessmentConfig)
  .config(companyNewsConfig)
  .config(shareConfig)
