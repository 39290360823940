import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import performIcon from '@/assets/icons/lms-perform.svg'
import manageIcon from '@/assets/icons/lms-manage.svg'
import assignIcon from '@/assets/icons/lms-assign.svg'
import groupIcon from '@/assets/icons/lms-group.svg'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import { UISref } from '@/react/components/UISref'
import { useAngularServices } from '@/react/components'

export const NavBar = ({}) => {
  const { stateService } = useRouter()
  const { CurrentUser } = useAngularServices()
  const [isManageVisible, setManageVisible] = useState(true)

  useEffect(() => {
    const role = CurrentUser.getRole()

    if (
      role === 'supervisor' ||
      role === 'client_user' ||
      role === 'read_only' ||
      role === 'trainee'
    ) {
      setManageVisible(false)
    }
  }, [])

  return (
    <div>
      <div className="tab-button-list" css={tabButtonList}>
        <UISref to={'trainings.program'}>
          <a
            className={classNames('tab', {
              active:
                stateService.current.name === 'trainings.program' ||
                stateService.current.name === 'trainings.topics' ||
                stateService.current.name === 'trainings.preview_content' ||
                stateService.current.name === 'trainings.trainee_quiz',
            })}
          >
            <img className="icon" src={performIcon} alt="view icon" />
            <p>Perform</p>
          </a>
        </UISref>
        {isManageVisible && (
          <UISref to={'trainings.manage'}>
            <a
              className={classNames('tab', {
                active:
                  stateService.current.name === 'trainings.manage' ||
                  stateService.current.name === 'trainings.topic',
              })}
            >
              <img className="icon" src={manageIcon} alt="add icon" />
              <p>Manage</p>
            </a>
          </UISref>
        )}
        {isManageVisible && (
          <UISref to={'trainings.assign.employees_list'}>
            <a
              className={classNames('tab', {
                active:
                  stateService.current.name ===
                    'trainings.assign.employees_list' ||
                  stateService.current.name ===
                    'trainings.assign.employee_assign' ||
                  stateService.current.name ===
                    'trainings.assign.trainings_list' ||
                  stateService.current.name ===
                    'trainings.assign.training_assign',
              })}
            >
              <img className="icon" src={assignIcon} alt="add icon" />
              <p>Assign</p>
            </a>
          </UISref>
        )}
        {/* <UISref to={'trainings.import'}>
          <a className={classNames('tab', {})}>
            <img className="icon" src={groupIcon} alt="add icon" />
            <p>Group</p>
          </a>
        </UISref>  */}
        {/* hidden since it is future work but it will not be available for several months */}
      </div>
    </div>
  )
}

const tabButtonList = css({
  width: '100%',
  marginTop: '20px',
  marginLeft: '5px',
  display: 'flex',
  '@media(max-width: 800px)': {
    '.tab': {
      p: {
        display: 'none',
      },
      '.nav__icon': {
        marginInline: '17px !important',
      },
    },
    '.active': {
      p: {
        display: 'flex',
      },
      '.nav__icon': {
        marginLeft: '25px !important',
        marginRight: '10px !important',
      },
    },
  },
  '.tab': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    borderRight: '1px solid #D3D3D3',
    textAlign: 'center',
    height: '45px',
    backgroundColor: '#E6E6E6',
    color: 'inherit',
    '.nav__icon': {
      marginLeft: '25px',
      width: '15px',
      height: '15px',
    },
    p: {
      marginLeft: '10px',
      marginRight: '25px',
      marginBottom: '0px',
    },
  },
  '.active': {
    backgroundColor: 'white',
  },
  '.upload__nav__icon': {
    width: '11px',
    height: '11px',
    marginLeft: '25px',
  },
})
