import {
  AdvancedFilter,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { singleHeaderTypes } from '../../components'
import { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { EnvolopeIcon } from '@/react/componentAssets/EnvelopeIcon'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import { TableRow } from '../commonComponents/TableRow'

export const DraftTable = ({ search, page, listCount, setListCount }) => {
  const [order, setOrder] = useState('-date_created')
  const { Api, CurrentUser, Notification } = useAngularServices()
  const [advancedFilters, setAdvancedFilters] = useState()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const { stateService } = useRouter()
  const [reportsList, setReportsList] = useState([])

  useEffect(() => {
    const getDraftReports = async () => {
      const { app } = stateService.params
      const { data } = await Api.get('reports', {
        application: app,
        is_draft: true,
        page,
        search,
        order,
        table: true,
      })
      setReportsList(data.results)
      setListCount(data.count)
    }

    getDraftReports()
  }, [page, search, order])

  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'table__header title__column',
      name: 'Title',
      type: 'report__title',
      additionalStyles: {
        minWidth: '296px',

        textAlign: 'left',
        padding: 0,
        paddingLeft: '14px',
        p: {
          display: 'flex',
          width: 'fit-content',
        },
        '.header-base': {
          width: 'fit-content',
          display: 'flex',
        },
        '.header-sort-arrow': {
          marginLeft: '3px',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '170px',
          },
        },
      },
      // additionalInnerElement: (
      //   <AdvancedFilter
      //     fieldName={'report__title'}
      //     advancedFilters={advancedFilters}
      //     setAdvancedFilters={setAdvancedFilters}
      //     updateUrl={false}
      //   />
      // ),
    },
    {
      className: 'table__header',
      name: 'Template',
      type: 'form__name',
      additionalStyles: {
        minWidth: '191px',
        width: '191px',
        padding: 0,
        textAlign: 'left',
        p: {
          display: 'flex',
        },
        '.header-base': {
          width: 'fit-content',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '170px',
          },
        },
      },
      // additionalInnerElement: (
      //   <AdvancedFilter
      //     fieldName={'form__name'}
      //     advancedFilters={advancedFilters}
      //     setAdvancedFilters={setAdvancedFilters}
      //     updateUrl={false}
      //   />
      // ),
    },
    {
      className: 'table__header',
      name: 'Name',
      type: 'user__first_name',
      additionalStyles: {
        minWidth: '135px',
        width: '135px',
        padding: 0,

        textAlign: 'left',
        p: {
          width: 'fit-content',
        },
        '.header-base': {
          width: 'fit-content',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '100px',
          },
        },
      },
      // additionalInnerElement: (
      //   <AdvancedFilter
      //     fieldName={'user__first_name'}
      //     advancedFilters={advancedFilters}
      //     setAdvancedFilters={setAdvancedFilters}
      //     updateUrl={false}
      //   />
      // ),
    },
    {
      className: 'table__header',
      name: 'Company',
      type: 'company_name',
      additionalStyles: {
        minWidth: '164px',
        width: '164px',
        padding: 0,
        textAlign: 'left',
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '130px',
          },
        },
      },
      //   additionalInnerElement: (
      //     <AdvancedFilter
      //       fieldName={'company_name'}
      //       advancedFilters={advancedFilters}
      //       setAdvancedFilters={setAdvancedFilters}
      //       updateUrl={false}
      //     />
      //   ),
    },
    {
      className: 'table__header',
      name: 'Project',
      type: 'project__name',
      filterHeader: true,
      additionalStyles: {
        minWidth: '93px',
        width: '93px',
        padding: 0,
        textAlign: 'left',
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '100px',
          },
        },
      },
      //   additionalInnerElement: (
      //     <AdvancedFilter
      //       fieldName={'project__name'}
      //       advancedFilters={advancedFilters}
      //       setAdvancedFilters={setAdvancedFilters}
      //       updateUrl={false}
      //     />
      //   ),
    },
    {
      className: 'table__header',
      name: 'Created',
      type: 'date_created',
      filterHeader: true,
      additionalStyles: {
        minWidth: '84px',
        width: '84px',
        padding: 0,
        textAlign: 'left',
        '.header-base': {
          '.header-sort-arrow': {
            marginLeft: '3px',
          },
          '.alert-button-opening': {
            marginLeft: '23px',
          },
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '70px',
          },
        },
      },
    },
    {
      className: 'table__header',
      name: 'Resend',
      type: '',
      filterHeader: true,
      additionalStyles: {
        minWidth: '78px',
        width: '78px',
        padding: 0,
        textAlign: 'center',
        '.header-base': {
          '.header-sort-arrow': {
            marginLeft: '3px',
          },
          '.alert-button-opening': {
            marginLeft: '23px',
          },
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '100px',
          },
        },
      },
    },
  ]

  return (
    <table css={baseStyle}>
      <TableHeaders
        headers={TABLE_HEADER_SETTINGS}
        callback={setOrder}
        popUpLocation={false}
        updateUrl={false}
        order={order}
        setOrder={setOrder}
      />
      <tbody>
        {reportsList.map((report, index) => (
          <TableRow
            key={index}
            report={report}
            index={index}
            color={variableColor}
          />
        ))}
      </tbody>
    </table>
  )
}

const baseStyle = css({
  tr: {
    position: 'relative',
  },
  td: {
    height: '40px',
    paddingRight: '10px',
  },
  '.title__td': {
    color: '#3981cd',
    paddingLeft: '15px',
    cursor: 'pointer',
  },
  '.documentation__row': {
    color: '#575757',
  },
  '.dark': {
    background: '#F2F3F5',
    borderBottom: '1px solid #E7EAEC',
    borderTop: '1px solid #E7EAEC',
  },
  '.envelope__td': {
    cursor: 'pointer',
    textAlign: 'center',
  },
  '.title__text': {
    margin: '0px',
    maxHeight: '40px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    paddingRight: '10px',
  },
  '.full__name': {
    position: 'absolute',
    zIndex: 1,
    border: '1px solid #D3D3D3',
    boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
    background: 'white',
    left: '353px',
    padding: '4px 11px',
    maxWidth: '400px',
  },
  '.title__fullname': {
    left: '50px',
  },
  '.company__fullname': {
    left: '650px',
  },
  '.name__fullname': {
    left: '550px',
  },
  '.project__fullname': {
    left: '850px',
  },
  '.title': {
    maxWidth: '296px',
  },
  '.template': {
    maxWidth: '191px',
  },
  '.name': {
    maxWidth: '135px',
  },
  '.company': {
    maxWidth: '164px',
  },
  '.project': {
    maxWidth: '93px',
  },
  '.created': {
    maxWidth: '84px',
  },
})
