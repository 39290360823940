import * as React from 'react'
import { getTranslatedString } from '@/utils'
import moment from 'moment'

import { css } from '@emotion/react'
import {
  dropdownFiledTheme,
  dropdownOptionStyles,
  NumberInput,
  useAngularServices,
} from '@/react/components'
import {
  getStartingValue,
  NoteElement,
} from '@screens/MobileForms/CreateEditPages/components'
import { default as ReactSelect } from 'react-select'

export const TimeField = ({
  fieldData,
  formMemory,
  possibleGroupData,
  onChange,
}) => {
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [error, setError] = React.useState(false)

  const startingOption = getStartingValue(
    possibleGroupData,
    formMemory,
    fieldData,
    'value',
  )

  const [hour, setHour] = React.useState<number>(
    startingOption
      ? changeStartingHour(Number(startingOption.split(':')[0]))
      : null,
  )
  const [minute, setMinute] = React.useState<number | string>(
    startingOption ? Number(startingOption.split(':')[1]) : '',
  )
  const [dayTime, setDayTime] = React.useState(
    Number(startingOption.split(':')[0]) > 11
      ? { label: 'PM', value: 'pm' }
      : { label: 'AM', value: 'am' },
  )

  const baseStyle = getBaseStyle()

  React.useEffect(() => {
    onChange({
      value: hour ? hourTransform(+hour, minute, dayTime.label) : null,
      possibleGroupData,
      id: fieldData.id,
      fieldType: 'time',
    })
  }, [hour, minute, dayTime])

  React.useEffect(() => {
    if (fieldData.required && !hour && !minute) {
      setError(true)
    }
  }, [hour, minute])

  const uniqueId =
    fieldData.id + (possibleGroupData ? '_' + possibleGroupData : '_set_id_0')

  return (
    <section id={uniqueId} css={baseStyle}>
      <div className="label-holder">
        <label className="header-label">
          {getTranslatedString(fieldData.name)}
          {fieldData.required ? ' *' : null}
        </label>
        {fieldData.note ? <NoteElement noteText={fieldData.note} /> : null}
      </div>
      <div className="input-row">
        <div className="single-input">
          <NumberInput
            defaultValue={hour}
            width={'60px'}
            maxNumber={12}
            valueCallback={(value) => {
              setHour(value)
              setError(!value)
            }}
            height={30}
            placeholder={'HH'}
            error={error}
          />
        </div>
        <h3>:</h3>
        <div className="single-input">
          {Number(startingOption.split(':')[1]) < 10 ? (
            <NumberInput
              defaultValue={minute === 0 ? '00' : minute}
              textInputDefaultValue={minute ? '0' + minute : ''}
              width={'60px'}
              maxNumber={59}
              valueCallback={(value) => {
                setMinute(value)

                if (value === 0) {
                  setError(false)
                } else {
                  setError(!value || !hour)
                }
              }}
              height={30}
              placeholder={'MM'}
              fakeNumberInput={true}
              error={error}
            />
          ) : (
            <NumberInput
              defaultValue={minute}
              width={'60px'}
              maxNumber={59}
              valueCallback={(value) => {
                setMinute(value)
                setError(!value || !hour)
              }}
              height={30}
              placeholder={'MM'}
              error={error}
            />
          )}
        </div>
        <div className="select-holder">
          <ReactSelect
            options={[
              { label: 'AM', value: 'am' },
              { label: 'PM', value: 'pm' },
            ]}
            defaultValue={dayTime}
            onChange={(value) => {
              setDayTime(value)
              if (!value && fieldData.required) {
                setError(true)
              } else {
                setError(false)
              }
            }}
            styles={{
              ...dropdownOptionStyles(variableColor, '30px'),
              control: (provided, state) => ({
                ...provided,
                ...dropdownStyle(error),
              }),
            }}
            theme={(theme) => dropdownFiledTheme(theme, variableColor)}
          />
        </div>
      </div>
    </section>
  )
}
function getBaseStyle() {
  return css({
    marginBottom: 15,
    '.header-label': { color: 'inherit' },
    '.input-row': {
      display: 'flex',
    },
    h3: {
      marginLeft: 2,
      marginRight: 2,
    },
    '.select-holder': {
      marginLeft: 10,
      width: 85,
    },
  })
}

function dropdownStyle(error) {
  return {
    minHeight: '0px',
    height: '30px',
    border: error && '1px solid #C80404',
  }
}

function hourTransform(
  hour: number,
  minute: number | string,
  daytime: string,
): string {
  const day = moment(`${hour} ${minute} ${daytime}`, 'h m A')
  return day.format('HH:mm:ss')
}

function changeStartingHour(hour: number): number {
  if (hour === 0) {
    return 12
  }
  if (hour > 12) {
    return hour - 12
  } else {
    return hour
  }
}
