import { makeLoadResolver, resolvers } from '../../app/routes/resolvers'

/** @ngInject */
export function toolboxTalksRouteConfig($stateProvider) {
  $stateProvider
    .state('app.toolboxes.add', {
      controller: function () {},
      template: `<toolbox-talks-create-page></toolbox-talks-create-page>`,
      url: '/add-toolbox-talk?previousPageNumber&previousOrder&previousReverse&previousSearch',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionCreateToolboxTalk'),
      },
    })
    .state('app.toolboxes.edit', {
      controller: function () {},
      template: `<toolbox-talks-create-page></toolbox-talks-create-page>`,
      url: '/toolboxes/:toolbox/edit?previousPageNumber&previousOrder&previousReverse&previousSearch',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionCreateToolboxTalk'),
      },
    })
    .state('app.toolboxes.view', {
      controller: function () {},
      template: `<toolbox-talks-view-internal-page></toolbox-talks-view-internal-page>`,
      url: '/toolboxes/:toolbox/view?previousPageNumber&previousOrder&previousReverse&previousSearch',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionToSeeToolboxTalk'),
      },
    })
    .state('share.toolbox', {
      controller: function () {},
      template: `<toolbox-talks-view-external-page></toolbox-talks-view-external-page>`,
      url: '/share/toolbox/:key',
    })
    .state('share.external-signatures', {
      controller: function () {},
      template: `<toolbox-talks-external-signatures-page></toolbox-talks-external-signatures-page>`,
      url: '/share/external-signatures/toolbox/:key/:topicId/:projectId/:clientId/router/:appId',
    })
    .state('app.toolboxes.deleted', {
      controller: function () {},
      template: `<toolbox-talks-deleted-list-page></toolbox-talks-deleted-list-page>`,
      url: '/toolboxes/deleted?pageNumber&order&reverse&search',
      resolve: {
        ...resolvers.appResolver,
        ...resolvers.PreviousStateResolver,
      },
    })
    .state('app.toolboxes.list', {
      url: '/toolboxes?pageNumber&order&search&reverse',
      template: '<toolbox-talks-list-page></toolbox-talks-list-page>',
      resolve: {
        ...resolvers.appResolver,
        ...resolvers.PreviousStateResolver,
      },
    })
    .state('app.toolboxes.toolbox_to_project', {
      url: '/toolboxes-by-project?pageNumber&order&reverse&search',
      template: '<toolbox-talk-assign></toolbox-talk-assign>',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionAssignToolboxTalkToProject'),
      },
    })
    .state('app.toolboxes.assign', {
      url: '/toolboxes/:project/assign?previousPageNumber&previousOrder&previousReverse&previousSearch',
      template: '<toolbox-talk-assign-project></toolbox-talk-assign-project>',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionAssignToolboxTalkToProject'),
      },
    })
    .state('app.toolboxes.project_to_toolbox', {
      url: '/toolboxes-by-toolbox?pageNumber&order&search&reverse',
      template:
        '<toolbox-talk-assign-project-list></toolbox-talk-assign-project-list>',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionAssignToolboxTalkToProject'),
      },
    })
    .state('app.toolboxes.assign_projects', {
      url: '/toolboxes/:toolbox/projects?previousPageNumber&previousOrder&previousReverse&previousSearch',
      template:
        '<toolbox-talk-assign-to-project></toolbox-talk-assign-to-project>',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionAssignToolboxTalkToProject'),
      },
    })
    .state('app.toolboxes.toolbox_templates', {
      url: '/toolboxes/templates/?page&previousOrder&previousReverse&previousSearch',
      template:
        '<toolbox-talk-templates-component></toolbox-talk-templates-component>',
      resolve: {},
    })
    .state('app.toolboxes.toolbox_templates_view', {
      url: '/toolboxes/templates/:toolbox/view?previousPageNumber&previousOrder&previousReverse&previousSearch',
      template: `<toolbox-template-view-component></toolbox-template-view-component>`,
      controller: function () {},
      resolve: resolvers.appResolver,
    })
    .state('app.toolboxes.groups', {
      url: '/toolboxes/groups?pageNumber&order&reverse&search',
      template: '<toolbox-talk-groups-component />',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionViewToolboxTalkGroups'),
      },
    })
    .state('app.toolboxes.group_assign', {
      url: '/toolboxes/groups/:group/assign?previousPageNumber&previousOrder&previousReverse&previousSearch',
      template: '<assign-toolbox-to-group-component />',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionViewToolboxTalkGroups'),
      },
    })
}
