import * as React from 'react'
import { useAngularServices } from '@/react/components'
import { LoginPage } from './Login/LoginPage'
import { ZurichLoginPage } from './ZurichLogin/ZurichLoginPage'

export const LoginPageRedirect = () => {
  const { Configuration } = useAngularServices()
  if (
    Configuration.appEnv === 'zurich-dev' ||
    Configuration.appEnv === 'zurich-production' ||
    Configuration.appEnv === 'zurich-master' ||
    Configuration.appEnv === 'zurich-staging'
  )
    return <ZurichLoginPage />

  return <LoginPage />
}
