import React, { useState } from 'react'
import pluralize from 'pluralize'
import {
  CommonPageHeader,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { css } from '@emotion/react'
import { singleHeaderTypes } from '../../components'
import { useRouter } from '@/react/hooks'
import { TableRow, PopupBaseAndOpener } from './components'

const PAGE_LENGTH = 20

export const SettingsPage = () => {
  const { $rootScope, Api, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()

  const app = $rootScope.mobile_apps.find((obj) => {
    return obj.id === Number(stateService.params.app)
  })
  const mobileForm = pluralize.singular(app.app_mobile_form_display) as string
  const project = pluralize.singular(app.project) as string
  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'name',
      name: `${mobileForm} Name`,
      type: 'name',
      filterHeader: true,
      additionalStyles: {
        minWidth: '600px',
        width: '600px',
        maxWidth: '600px',
        textAlign: 'left',
      },
    },
    {
      className: 'custom-email',
      name: 'Custom Email',
      type: 'Custom Email',
      filterHeader: false,
      additionalStyles: {
        minWidth: '150px',
        width: '150px',
        maxWidth: '150px',
        textAlign: 'center',
      },
      additionalInnerElement: (
        <PopupBaseAndOpener
          mobileForm={mobileForm}
          project={project}
          alertType={'alert'}
        />
      ),
    },
    {
      className: 'alerts',
      name: 'Alerts',
      type: 'alerts',
      filterHeader: false,
      additionalStyles: {
        minWidth: '150px',
        width: '150px',
        maxWidth: '150px',
        textAlign: 'center',
      },
      additionalInnerElement: (
        <PopupBaseAndOpener
          mobileForm={mobileForm}
          project={project}
          alertType={'alert'}
        />
      ),
    },
    {
      className: 'privacy',
      name: 'Privacy Setting',
      type: 'privacy',
      filterHeader: false,
      additionalStyles: {
        minWidth: '150px',
        width: '150px',
        maxWidth: '150px',
        textAlign: 'center',
      },
      additionalInnerElement: (
        <PopupBaseAndOpener mobileForm={mobileForm} project={project} />
      ),
    },
  ]

  const [page, setPage] = React.useState(
    parseInt(stateService.params.pageNumber) || 1,
  )
  const [count, setCount] = React.useState<number>(0)
  const [order, setOrder] = React.useState(stateService.params.order || 'name')
  const [search, setSearch] = React.useState(stateService.params.search || '')
  const [tableData, setTableData] = React.useState([])
  const [refresh, setRefresh] = React.useState(false)
  const appId: string = stateService.params.app
  const user = CurrentUser.getInstance()

  React.useEffect(() => {
    fetchData()
  }, [page, search, order, refresh])

  function fetchData() {
    const params = {
      page: page,
      client_id: user.client.id,
      application: appId,
      annotate_app_project_count: true,
      serializer: 'index',
      is_active: true,
    }

    if (search) params.search = search
    if (order) params.order = order

    Api.MobileForms.get(params, (resp) => {
      setTableData(resp.results)
      setCount(resp.count)
    })
    setRefresh(false)
  }

  return (
    <section css={baseStyle}>
      <CommonPageHeader headerText="Mobile Form Settings" />
      <main>
        <div className="search-base">
          <Search
            search={search}
            searchCallback={setSearch}
            pageCallback={setPage}
          />
        </div>
        <table>
          <TableHeaders
            headers={TABLE_HEADER_SETTINGS}
            popUpLocation={false}
            callback={setOrder}
          />
          <tbody>
            {tableData.length
              ? tableData.map((row, idx) => (
                  <TableRow
                    idx={idx}
                    rowData={row}
                    search={search}
                    setRefresh={setRefresh}
                  />
                ))
              : null}
          </tbody>
        </table>
        <div className="page-switcher-base">
          <PageSwitcher
            pages={PAGE_LENGTH}
            listCount={count}
            currentPage={page}
            callback={setPage}
          />
        </div>
      </main>
    </section>
  )
}

const subTitle = css({
  padding: '14px 23px 18px 23px',
  fontSize: '14px',
  fontWeight: 600,
  borderBottom: '1px solid #EEEFF3',
  marginTop: '15px',
  background: '#fff',
})

const baseStyle = css({
  paddingBottom: '300px',
  main: {
    width: 'fit-content !important',
    marginTop: '20px',
    padding: '20px',
    background: '#fff',
  },
  '#search': {
    width: '264px',
  },
  '.search-base': {
    marginBottom: '19px',
  },
  table: {
    'thead:first-child': {
      borderBottom: '1.5px solid #B3B3B3',
      p: {
        margin: 0,
      },
    },
    tbody: {
      position: 'relative',
      tr: {
        height: '40px',
        td: {
          height: '40px',
          textAlign: 'center',
          padding: '0 5px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          img: {
            height: '18px',
            cursor: 'pointer',
          },
        },
        '.textAlignLeft': {
          textAlign: 'left',
          paddingLeft: '20px',
          maxWidth: '600px',
        },
      },
    },
  },
  '@media(max-width: 1300px)': {
    main: {
      width: '100% !important',
      overflowX: 'scroll',
    },
  },
})
