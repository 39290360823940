angular.module('ccs').service('VariableThemeColor', VariableThemeColor)

import { percentageShadeBlendColor } from '../../utils/colorAdjustment'

//our standard blue color:
//#3682CF

function VariableThemeColor() {
  return {
    mainColorSet: (color, external = false) => {
      const backgroundGradientForNav =
        '.nav-header {' +
        'background-size: 100% 100%; ' +
        'background-position: 0px 0px,0px 0px,0px 0px,0px 0px; ' +
        'background-image: conic-gradient(from 134deg at 43% 15%, ' +
        percentageShadeBlendColor(0.9, color, 'rgb(32,32,32)') +
        ' 40%, #23354600 40%),' +
        'conic-gradient(from 257deg at 43% 15%, ' +
        percentageShadeBlendColor(0.9, color, 'rgb(38,38,38)') +
        ' 38%, #23354600 38%),' +
        'conic-gradient(from 287deg at 43% 15%,' +
        percentageShadeBlendColor(0.9, color, 'rgb(43,43,43)') +
        ' 38%, #23354600 38%),' +
        'conic-gradient(from 25deg at 43% 15%, ' +
        percentageShadeBlendColor(0.9, color, 'rgb(42,42,42)') +
        ' 38%, #23354600 38%);} '
      const mix90With323232 =
        '.nav > li.active {' +
        'background-color:' +
        percentageShadeBlendColor(0.9, color, 'rgb(32,32,32)') +
        '}'
      const mix90With424242 =
        'body {' +
        'background-color:' +
        (external
          ? color
          : percentageShadeBlendColor(0.9, color, 'rgb(42,42,42)')) +
        '}'
      const mix92With424242 =
        '.navbar-default .nav > li > a:hover, .navbar-default .nav > li > a:focus ' +
        '{' +
        'background-color:' +
        percentageShadeBlendColor(0.92, color, 'rgb(42,42,42)') +
        '}'
      const backgroundsColor =
        'body, ' +
        '.nav-header, ' +
        '.pace .pace-progress, ' +
        '.label-primary, ' +
        '.badge-primary, ' +
        '.spin-icon, ' +
        '.branding, ' +
        '.navbar-default .navbar-brand, ' +
        '.top-navigation .navbar-brand, ' +
        '.nav .open > a, ' +
        '.nav .open > a:hover, ' +
        '.nav .open > a:focus, ' +
        '.jvectormap-zoomin, ' +
        '.jvectormap-zoomout, ' +
        '.jvectormap-goback, ' +
        '.navbar-default .special_link a, ' +
        '.social-icon a, ' +
        'li.pricing-title, ' +
        '.btn-primary, ' +
        '.navbar-toggle, ' +
        'body.mini-navbar .nav-header, ' +
        '.testimonials, ' +
        '.btn-primary, ' +
        '.product-price, ' +
        '.panel-primary > .panel-heading, ' +
        '.progress-bar, ' +
        '.btn-primary, ' +
        '.onoffswitch-inner:before, ' +
        '.fc-state-active, ' +
        '.fc-event, ' +
        '.fc-agenda .fc-event-time, ' +
        '.fc-event a, ' +
        'a.list-group-item.active, ' +
        'a.list-group-item.active:hover, ' +
        'a.list-group-item.active:focus, ' +
        '.toast, ' +
        '.toast-success, ' +
        '.navbar-default .landing_link a:hover, ' +
        '.landing-page .navbar-default .navbar-nav > li > a:focus, ' +
        '.landing-page .navbar-default .navbar-nav > .active > a, ' +
        '.landing-page .navbar-default .navbar-nav > .active > a:hover, ' +
        '.landing-page .navbar-scroll.navbar-default .nav li a:hover, ' +
        '.landing-page .features small, ' +
        '.landing-page .features-icon, ' +
        '.landing-page .pricing-plan .pricing-price span, ' +
        '.landing-page .features .big-icon, ' +
        '.md-skin.landing-page .navbar-default .navbar-nav > li > a:hover, ' +
        '.md-skin.landing-page .navbar-default .navbar-nav > li > a:focus, ' +
        '.md-skin.top-navigation .nav > li a:hover, .top-navigation .nav > li a:focus, ' +
        '.md-skin.top-navigation .nav > li a:hover, .top-navigation .nav > li a:focus, ' +
        '.md-skin.top-navigation .nav .open > a, ' +
        '.md-skin.top-navigation .nav .open > a:hover, ' +
        '.md-skin.top-navigation .nav .open > a:focus, ' +
        '.md-skin.top-navigation .nav > li.active a, ' +
        '.defaultBg, ' +
        '.checkbox-container input:checked ~ .checkmark, ' +
        '.mobile-form-page .report-title-warn, ' +
        '.sk-spinner-rotating-plane.sk-spinner, ' +
        '.sk-spinner-double-bounce .sk-double-bounce1, .sk-spinner-double-bounce .sk-double-bounce2, ' +
        '.sk-spinner-wave div, ' +
        '.sk-spinner-wandering-cubes .sk-cube1, .sk-spinner-wandering-cubes .sk-cube2, ' +
        '.sk-spinner-pulse.sk-spinner, ' +
        '.sk-spinner-chasing-dots .sk-dot1, .sk-spinner-chasing-dots .sk-dot2, ' +
        '.sk-spinner-three-bounce div, ' +
        '.sk-spinner-circle .sk-circle:before, ' +
        '.sk-spinner-cube-grid .sk-cube, ' +
        '.sk-spinner-wordpress.sk-spinner, ' +
        '.sk-spinner-fading-circle .sk-circle:before, ' +
        '.btn-default-secondary:hover, ' +
        '.btn-default-primary, ' +
        '.btn-success, ' +
        '.label-success, .badge-success, ' +
        '.panel-success > .panel-heading, ' +
        '.progress-bar-success, ' +
        '.control input:checked ~ .control_indicator, ' +
        '.control input:checked ~ .control_indicator-selectAll ,' +
        '.control:hover input:not([disabled]):checked ~ .control_indicator, ' +
        '.control:hover input:not([disabled]):checked ~ .control_indicator-selectAll,' +
        '.control input:checked:focus ~ .control_indicator, ' +
        '.control input:checked:focus ~ .control_indicator-selectAll, ' +
        '.ui-select-bootstrap .ui-select-choices-row.active>span, ' +
        'input:checked + .slider, ' +
        '.minimalize-styl-2, ' +
        '.btn-default-border:hover, ' +
        '.pagination > li:last-child > a, ' +
        '.pagination > li:last-child > span, ' +
        '.pagination > li:first-child > a,' +
        '.pagination > li:first-child > span, ' +
        '.pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus, ' +
        '.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus ' +
        '{ background-color: ' +
        color +
        ' !important; } '
      const borderColorDarker3 =
        '.btn-primary:focus, ' +
        '.btn-primary:active, ' +
        '.btn-primary.active, ' +
        '.open .dropdown-toggle.btn-primary, ' +
        '.btn-primary:active:focus, ' +
        '.btn-primary:active:hover, ' +
        '.btn-primary.active:hover, ' +
        '.btn-primary.active:focus,' +
        '.btn-success:hover, ' +
        '.btn-success:focus, ' +
        '.btn-success:active, ' +
        '.btn-success.active, ' +
        '.open .dropdown-toggle.btn-success, ' +
        '.btn-success:active:focus, ' +
        '.btn-success:active:hover, ' +
        '.btn-success.active:hover, ' +
        '.btn-success.active:focus ' +
        '{ border-color: ' +
        percentageShadeBlendColor(-0.3, color) +
        ' !important; } '
      const borderColorDarker5 =
        '.btn-primary:hover,' +
        '.btn-primary:focus,' +
        '.btn-primary:active,' +
        '.btn-primary.active,' +
        '.open .dropdown-toggle.btn-primary ' +
        ' { border-color: ' +
        percentageShadeBlendColor(-0.5, color) +
        ' !important; } '
      const textColorDarker5 =
        '.faq-question:hover ' +
        ' { color: ' +
        percentageShadeBlendColor(-0.5, color) +
        ' !important; } '
      const textColorLighter3 =
        '.navbar-default .landing_link a span.label ' +
        ' { color: ' +
        percentageShadeBlendColor(0.3, color) +
        ' !important; } '
      const backgroundColorDarker5 =
        '.navbar-default .nav > li > a:hover, .navbar-default .nav > li > a:focus, ' +
        '.navbar-default .navbar-brand:hover, ' +
        '.navbar-default .navbar-brand:focus, ' +
        '.btn-default-border:active,' +
        '.landing-page .social-icon a:hover,' +
        '.btn-primary:hover,' +
        '.btn-primary:focus,' +
        '.btn-primary:active,' +
        '.btn-primary.active,' +
        '.open .dropdown-toggle.btn-primary ' +
        '{ background-color: ' +
        percentageShadeBlendColor(-0.5, color) +
        ' !important; } '
      const backgroundColorDarker3 =
        '.nav > li.active, ' +
        '.open .dropdown-toggle.btn-primary, ' +
        '.btn-primary:active:focus, ' +
        '.btn-primary:active:hover, ' +
        '.btn-primary.active:hover, ' +
        '.btn-primary.active:focus, ' +
        '.btn-success:hover, ' +
        '.btn-success:focus, ' +
        '.btn-success:active, ' +
        '.btn-success.active, ' +
        '.open .dropdown-toggle.btn-success, ' +
        '.btn-success:active:focus, ' +
        '.btn-success:active:hover, ' +
        '.btn-success.active:hover, ' +
        '.btn-success.active:focus ' +
        '{ background-color: ' +
        percentageShadeBlendColor(-0.3, color) +
        ' !important; } '
      const backgroundColorLighter4 =
        '.navbar-default .landing_link a, ' +
        '.btn-primary.disabled, ' +
        '.btn-primary.disabled:hover, ' +
        '.btn-primary.disabled:focus, ' +
        '.btn-primary.disabled:active, ' +
        '.btn-primary.disabled.active, ' +
        '.btn-primary[disabled], ' +
        '.btn-primary[disabled]:hover, ' +
        '.btn-primary[disabled]:focus, ' +
        '.btn-primary[disabled]:active, ' +
        '.btn-primary.active[disabled], ' +
        '.btn-success.disabled, ' +
        '.btn-success.disabled:hover, ' +
        '.btn-success.disabled:focus, ' +
        '.btn-success.disabled:active, ' +
        '.btn-success.disabled.active, ' +
        '.btn-success[disabled], ' +
        '.btn-success[disabled]:hover, ' +
        '.btn-success[disabled]:focus, ' +
        '.btn-success[disabled]:active, ' +
        '.btn-success.active[disabled], ' +
        'fieldset[disabled] .btn-success, ' +
        'fieldset[disabled] .btn-success:hover, ' +
        'fieldset[disabled] .btn-success:focus, ' +
        'fieldset[disabled] .btn-success:active, ' +
        'fieldset[disabled] .btn-success.active ' +
        '{ background-color: ' +
        percentageShadeBlendColor(0.4, color) +
        ' !important; } '
      const selectedBackground =
        '.custom-select-dropdown .custom-select-dropdown-list .active-row ' +
        '{ background-color: ' +
        percentageShadeBlendColor(0.6, color) +
        ' !important; } '
      const backgroundColorLighter8 =
        '.report-collapsed-container-blue .report-header-container-collapsed-blue .ibox-title, ' +
        '.observation-report-name, ' +
        '.report-preview-header-container-collapsed-blue, ' +
        '.base-print-report .print-header, ' +
        '.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus ' +
        '{ background-color: ' +
        percentageShadeBlendColor(0.8, color) +
        ' !important; } '
      const borderColorLighter4 =
        '.navbar-default .landing_link a, ' +
        '.btn-primary.disabled, ' +
        '.btn-primary.disabled:hover, ' +
        '.btn-primary.disabled:focus, ' +
        '.btn-primary.disabled:active, ' +
        '.btn-primary.disabled.active, ' +
        '.btn-primary[disabled], ' +
        '.btn-primary[disabled]:hover, ' +
        '.btn-primary[disabled]:focus, ' +
        '.btn-primary[disabled]:active, ' +
        '.btn-primary.active[disabled], ' +
        'fieldset[disabled] .btn-primary, ' +
        'fieldset[disabled] .btn-primary:hover, ' +
        'fieldset[disabled] .btn-primary:focus, ' +
        'fieldset[disabled] .btn-primary:active, ' +
        'fieldset[disabled] .btn-primary.active, ' +
        '.btn-success.disabled, ' +
        '.btn-success.disabled:hover, ' +
        '.btn-success.disabled:focus, ' +
        '.btn-success.disabled:active, ' +
        '.btn-success.disabled.active, ' +
        '.btn-success[disabled], ' +
        '.btn-success[disabled]:hover, ' +
        '.btn-success[disabled]:focus, ' +
        '.btn-success[disabled]:active, ' +
        '.btn-success.active[disabled], ' +
        'fieldset[disabled] .btn-success, ' +
        'fieldset[disabled] .btn-success:hover, ' +
        'fieldset[disabled] .btn-success:focus, ' +
        'fieldset[disabled] .btn-success:active, ' +
        'fieldset[disabled] .btn-success.active ' +
        '{ border-color: ' +
        percentageShadeBlendColor(0.4, color) +
        ' !important; } '
      const mediaMaxWidth768color =
        '@media (max-width: 768px) {' +
        '.landing-page .navbar-default .nav li a:hover, ' +
        '.landing-page .navbar-default .nav li a:hover ' +
        '{ background-color: ' +
        color +
        ' !important; }} '
      const borderLeft =
        '.nav > li.active { border-left-color: ' + color + ' !important; } '
      const border =
        '.panel-primary, ' +
        '.btn-primary, ' +
        '.panel-primary > .panel-heading, ' +
        '.btn-primary, ' +
        '.btn-default-border, ' +
        '.fc-state-active, ' +
        '.fc-state-active, ' +
        '.fc-event a, ' +
        'a.list-group-item.active, ' +
        'a.list-group-item.active:hover, ' +
        'a.list-group-item.active:focus, ' +
        '.form-control:focus, ' +
        '.single-line:focus, ' +
        '.has-success .form-control, ' +
        '.mobile-form-page .report-title-warn, ' +
        '.btn-default-secondary, ' +
        '.btn-default-secondary:hover, ' +
        '.btn-default-primary, ' +
        '.btn-success, ' +
        'input:checked + .slider, ' +
        '.btn-default-border, ' +
        'input.general-input-style:focus, ' +
        '.btn-ccs-border-btn, ' +
        '.dz-progress ' +
        '{ border-color: ' +
        color +
        ' !important; } '
      const shadowDimDarker6 =
        'button.btn-primary.dim, ' +
        'button.btn-primary.dim:active, ' +
        'button.btn-success.dim, ' +
        'button.btn-success.dim:active ' +
        'box-shadow: inset 0 0 0 ' +
        percentageShadeBlendColor(-0.6, color) +
        ', 0 5px 0 0 ' +
        percentageShadeBlendColor(-0.6, color) +
        ', 0 10px 5px #999;'
      const textColor =
        '.top-navigation .navbar-nav .dropdown-menu > .active > a, ' +
        '.top-navigation .nav > li a:hover, ' +
        '.top-navigation .nav > li a:focus, ' +
        '.btn-primary.btn-outline,' +
        '.btn-link:hover, ' +
        '.btn-link:focus, ' +
        '.btn-link:active, ' +
        '.btn-link.active, ' +
        '.open .dropdown-toggle.btn-link, ' +
        '.btn-default-border, ' +
        '.has-success .control-label, ' +
        '.top-navigation .nav > li.active > a, ' +
        '.forum-item.active .fa, ' +
        '.forum-item.active a.forum-item-title, ' +
        '.vertical-date small, ' +
        '.slick-prev:before, ' +
        '.slick-next:before, ' +
        '.file-list li a:hover, ' +
        '.faq-question, ' +
        '.issue-tracker .btn-link, ' +
        '.product-name:hover, ' +
        '.product-name:focus, ' +
        '.vote-actions a, ' +
        '.vote-info a:hover, ' +
        '.vote-icon.active, ' +
        '.ccs-link, ' +
        '.ccs-link:focus, ' +
        '.header-sort-arrow, ' +
        'ul[dnd-list] li.dndDragging .drag-handle, ' +
        '.link-white:hover, ' +
        '.edit-name-inline, ' +
        '.observation-score, ' +
        '.observation-score, ' +
        '.variable-color-text, ' +
        '.btn-default-secondary, ' +
        '.btn-success.btn-outline, ' +
        '.btn-default-border, ' +
        '.ccs-link-default, ' +
        '.btn-ccs-border-btn, ' +
        '.observation-content div span, ' +
        '.field-with-group-edit label, ' +
        '.base-print-report .report-header-item span, ' +
        '.pagination > li > a, .pagination > li > span ' +
        '{ color: ' +
        color +
        ' !important; } '
      const uploadingBar =
        '.dz-progress ' +
        '{background-image:repeating-linear-gradient( -45deg,' +
        color +
        ', ' +
        color +
        ' 11px, #eee 10px, #eee 20px)!important;}'
      const styleTag = $(
        '<style>' +
          backgroundsColor +
          borderLeft +
          border +
          textColor +
          mediaMaxWidth768color +
          backgroundColorDarker3 +
          borderColorDarker3 +
          backgroundColorDarker5 +
          borderColorDarker5 +
          textColorDarker5 +
          shadowDimDarker6 +
          backgroundColorLighter4 +
          backgroundColorLighter8 +
          borderColorLighter4 +
          textColorLighter3 +
          uploadingBar +
          selectedBackground +
          '</style>',
      )
      $('html > head').append(styleTag)
    },
  }
}
