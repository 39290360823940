import * as React from 'react'
import { css } from '@emotion/react'

import { QuestionField } from './componets'
import { useAngularServices } from '@/react/components'
import { sortByAnswer } from '@/utils/data/SortByAnswer'

const questionField = (app) => (item) =>
  <QuestionField key={item?.question?.id} questionFullData={item} app={app} />

export function QuestionsList({ lists, app, viewNaQuestions }: any) {
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const questionListBaseStyle = getBaseStyle(variableColor)

  return (
    <section css={questionListBaseStyle}>
      {lists['no'][0] ? (
        <div className="no">
          <div className="fake-margin-top"></div>
          <h3 className="header-for-answer-type">
            Follow Up Required - {lists['no'].length}
          </h3>
          {sortByAnswer(lists['no']).map(questionField(app))}
        </div>
      ) : null}
      {lists['pr'][0] ? (
        <div className="pr">
          <div className="fake-margin-top"></div>
          <h3 className="header-for-answer-type">
            Pending Review - {lists['pr'].length}
          </h3>
          {sortByAnswer(lists['pr']).map(questionField(app))}
        </div>
      ) : null}
      {lists['pa'][0] ? (
        <div className="pa">
          <div className="fake-margin-top"></div>
          <h3 className="header-for-answer-type">
            Pending Approval - {lists['pa'].length}
          </h3>
          {sortByAnswer(lists['pa']).map(questionField(app))}
        </div>
      ) : null}
      {lists['cls'][0] ? (
        <div className="cls">
          <div className="fake-margin-top"></div>
          <h3 className="header-for-answer-type">
            Issue Resolved - {lists['cls'].length}
          </h3>
          {sortByAnswer(lists['cls']).map(questionField(app))}
        </div>
      ) : null}
      {lists['yes'][0] ? (
        <div className="yes">
          <div className="fake-margin-top"></div>
          <h3 className="header-for-answer-type">
            Yes - {lists['yes'].length}
          </h3>
          {sortByAnswer(lists['yes']).map(questionField(app))}
        </div>
      ) : null}
      {viewNaQuestions && lists['n/a'][0] ? (
        <div className="n/a">
          <div className="fake-margin-top"></div>
          <h3 className="header-for-answer-type">
            N/A - {lists['n/a'].length}
          </h3>
          {sortByAnswer(lists['n/a']).map(questionField(app))}
        </div>
      ) : null}
    </section>
  )
}

function getBaseStyle(variableColor) {
  return css({
    width: '100%',
    '.fake-margin-top': { height: 75, width: '100%' },
    '.header-for-answer-type': {
      textAlign: 'center',
      color: 'white !important',
      backgroundColor: variableColor + ' !important',
      fontSize: '24px',
      fontWeight: 100,
      width: '100%',
      margin: 0,
      paddingBottom: 5,
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
      pageBreakInside: 'avoid',
      pageBreakBefore: 'auto',
      pageBreakAfter: 'auto',
    },
  })
}
