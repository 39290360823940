import * as React from 'react'

import { getTimeCardNavigationData } from '../components/commonVariables'
import { ReloadableElements } from './components'

import {
  useAngularServices,
  FloatingWindowHorizontalNavigation,
  CommonPageHeader,
} from '@/react/components'
import { useRouter } from '@/react/hooks'
import { AnalyticsIcon } from '@/react/componentAssets/AnalyticsIcon'
import { css } from '@emotion/react'

export const CurrentPage = () => {
  const { $rootScope, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const role = CurrentUser.getRole()

  if (role !== 'client_admin' && role !== 'supervisor') {
    stateService.go('index.dashboard', {})
    return
  }

  const timeCardCheck = $rootScope.mobile_apps.filter((obj) => {
    return obj.id === Number(stateService.params.app)
  })[0].has_timecard

  if (!timeCardCheck) {
    stateService.go('index.dashboard', {})
    return null
  }

  return (
    <section css={baseStyle}>
      <CommonPageHeader
        headerText={
          $rootScope.mobile_apps.filter((obj) => {
            return obj.id === Number(stateService.params.app)
          })[0].main_menu_timecard
        }
      />
      <a
        href="https://rtrspro.knowledgetransfer.us/#/views/Timecard_module_Draft/TimecardMapDashboard?:iid=1&:original_view=y"
        className="header__icon"
        target="_blank"
      >
        <AnalyticsIcon color={variableColor} />
      </a>
      <FloatingWindowHorizontalNavigation
        navSettings={getTimeCardNavigationData('current')}
      />
      <ReloadableElements />
    </section>
  )
}

const baseStyle = css({
  h2: {
    position: 'relative',
  },
  '.header__icon': {
    position: 'absolute',
    left: '135px',
    top: '94px',
  },
})
