import { parseDate } from '@/utils'
import * as React from 'react'
import { headerDataInterface } from '@screens/Observations/ViewTemplates/components/types'
import { css } from '@emotion/react'
import classNames from 'classnames'

export const WildCardsAndNotes = ({
  headerData,
  tableStyle = false,
  variableColor,
  wildcard = true,
}: {
  headerData: headerDataInterface
  tableStyle: boolean
  variableColor: string
  wildcard?: boolean
}) => {
  function getBathProjectNameForNote(id, observationArray) {
    let returnString
    observationArray.forEach((observation) => {
      if (observation.id === id) returnString = observation.project.name
    })
    return returnString
  }

  if (tableStyle)
    return (
      <div css={getBaseStyle(variableColor)}>
        <div
          className={classNames('observation-notes-block', {
            'observation-wildcard-block': wildcard,
          })}
        >
          <div className="notes-header">
            <h3>
              {wildcard
                ? headerData.client.report_settings.wildcard_display
                : 'General Notes'}
            </h3>
            <div className="note-fill">
              {headerData[
                wildcard ? 'observation_wildcards' : 'observation_notes'
              ].map((row) => {
                return row.deleted ? null : (
                  <div key={row.id}>
                    {row.observation && headerData.observations_detail ? (
                      <span className="variable-color-text spam-spacing">
                        {getBathProjectNameForNote(
                          row.observation,
                          headerData.observations_detail,
                        )}
                      </span>
                    ) : null}
                    {row.category_name ? (
                      <span className="variable-color-text spam-spacing">
                        {row.category_name}&nbsp;&nbsp;
                      </span>
                    ) : null}
                    <span className="prepend-time-display">
                      {parseDate(row.date_created)}{' '}
                    </span>
                    <span className="variable-color-text">
                      {row.user.first_name} {row.user.last_name}
                      &nbsp;-&nbsp;
                    </span>
                    <span className="content__text">{row.text}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  return (
    <div css={getBaseStyle(variableColor)}>
      <div className="row">
        <div className="col-sm-12">
          <h3 className="text-center">
            <u>
              {wildcard
                ? headerData.client.report_settings.wildcard_display.toUpperCase()
                : 'GENERAL NOTES'}
            </u>
          </h3>
          <div className="form-group col-xs-12">
            {headerData[
              wildcard ? 'observation_wildcards' : 'observation_notes'
            ].map((row) => {
              return row.deleted ? null : (
                <div key={row.id}>
                  {row.observation && headerData.observations_detail ? (
                    <span className="variable-color-text">
                      {getBathProjectNameForNote(
                        row.observation,
                        headerData.observations_detail,
                      )}{' '}
                    </span>
                  ) : null}{' '}
                  {row.category_name ? (
                    <span className="variable-color-text">
                      {row.category_name}&nbsp;&nbsp;
                    </span>
                  ) : null}
                  <span
                    className="prepend-time-display"
                    css={css({ color: variableColor })}
                  >
                    {parseDate(row.date_created)}{' '}
                  </span>
                  <span className="variable-color-text">
                    {row.user.first_name} {row.user.last_name}
                    &nbsp;-&nbsp;
                  </span>
                  <span className="view__text">{row.text}</span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const getBaseStyle = (variableColor: string) =>
  css({
    '.observation-wildcard-block': {
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: '#eeeeee',
    },
    '.observation-notes-block': {
      width: '100%',
      '.notes-header': {
        paddingTop: 60,
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: '24px',
        fontWeight: 100,
        color: 'rgb(103, 106, 108) !important',
        h3: {
          textAlign: 'center',
          fontSize: '20px',
        },
      },
      '.note-fill': {
        fontSize: '12px',
      },
    },
    '.spam-spacing': {
      marginRight: 5,
    },
    '.prepend-time-display': {
      color: variableColor,
      fontWeight: 'bold',
    },
    '.variable-color-text': {
      fontWeight: 'bold',
    },
  })
