import * as React from 'react'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { flatMap } from 'lodash'
import {
  ApiService,
  DataWrap,
  ObservationBody,
  PaginatedGet,
  ReportQuestion,
} from '@/react/types'

export const useFetchObservations = () => {
  const [fetchedData, setFetchedData] = React.useState([null, null])

  const { Api, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()

  const observationID = stateService.params.observation

  React.useEffect(() => {
    async function fetchData() {
      if (observationID) {
        const requestParams = {
          deleted: '',
          observation: observationID,
          page_size: 1000,
        }
        const [
          { data: baseObservationData },
          { data: questionsData },
          { data: answersData },
        ] = await Promise.all([
          Api.Observations.byIDWithoutDeletedParam(observationID),
          Api.Questions.get(requestParams),
          Api.Answers.get(requestParams),
        ])
        const allAnswers = [...answersData.results]
        if (questionsData.count - answersData.count > 0) {
          const answerIds = answersData.results.map((el) => el.question)
          const questionIds = baseObservationData?.questions.filter(
            (item) => !answerIds.includes(item),
          )
          for (let i = 0; i < questionIds.length; i++) {
            allAnswers.push({
              answer: 'n/a',
              question: questionIds[i],
              lat: null,
              lon: null,
              photos: [],
            })
          }
        }

        const allQuestions = [...questionsData?.results]
        if (
          baseObservationData?.questions?.length >
          questionsData?.results?.length
        ) {
          const questionIds = questionsData.results.map((el) => el.id)
          const deletedQuestionIds = baseObservationData?.questions.filter(
            (item) => !questionIds.includes(item),
          )
          for (let i = 0; i < deletedQuestionIds.length; i++) {
            await Api.Questions.byIDWithoutDeletedParam(
              deletedQuestionIds[i],
              (resp) => {
                allQuestions.push(resp)
              },
            )
          }
          allQuestions.sort(
            (a, b) =>
              baseObservationData.questions.findIndex((item) => item === a.id) -
              baseObservationData.questions.findIndex((item) => item === b.id),
          )
        }

        setFetchedData([
          baseObservationData,
          dataChange(allQuestions, allAnswers),
        ])
      } else if (
        stateService.params.key &&
        stateService.current.name === 'share.observation'
      ) {
        const { data: sharedObservationData } = await Api.get(
          'shares/observation/' + stateService.params.key,
          {},
        )
        CurrentUser.setClientSettings(
          sharedObservationData.client.general_settings,
        )
        const allAnswers = [...sharedObservationData.answers]
        if (
          sharedObservationData.questions.length >
          sharedObservationData.answers.length
        ) {
          const questionIds = sharedObservationData.questions.map((el) => el.id)
          const answerIds = sharedObservationData.answers.map(
            (el) => el.question,
          )
          const NAQuestionIds = questionIds.filter(
            (item) => !answerIds.includes(item),
          )

          for (let i = 0; i < NAQuestionIds.length; i++) {
            allAnswers.push({
              answer: 'n/a',
              question: NAQuestionIds[i],
              lat: null,
              lon: null,
              photos: [],
            })
          }
        }

        setFetchedData([
          sharedObservationData,
          dataChange(sharedObservationData.questions, allAnswers),
        ])
      } else if (
        stateService.params.key &&
        stateService.current.name === 'share.batch_report'
      ) {
        const { data: batchObservationData }: DataWrap<ObservationBody> =
          await Api.get(
            'shares/batch_report/' + stateService.params.key,
            {
              deleted: false,
            },
            () => {
              return
            },
            () => {
              return
            },
            true,
          )
        batchObservationData.questions = await getQuestions(
          Api,
          stateService.params.key,
          true,
        )
        CurrentUser.setClientSettings(
          batchObservationData.client.general_settings,
        )

        const allQuestions = []
        batchObservationData.observations_detail.forEach((el) =>
          allQuestions.push(...el.questions),
        )

        const wildcards = []
        Object.values(batchObservationData.observations_detail).forEach(
          (observation) => {
            observation.observation_wildcards.forEach((wildcard) => {
              if (!wildcard.deleted) wildcards.push(wildcard)
            })
          },
        )
        batchObservationData.observation_wildcards = wildcards

        const notes = []
        Object.values(batchObservationData.observations_detail).forEach(
          (observation) => {
            observation.observation_notes.forEach((note) => {
              if (!note.deleted) notes.push(note)
            })
          },
        )
        batchObservationData.observation_notes = notes

        setFetchedData([
          batchObservationData,
          batchDataChange(batchObservationData),
        ])
      } else if (stateService.params.batch_report) {
        const { data: batchObservationData }: DataWrap<ObservationBody> =
          await Api.get(
            'batch_report/' + stateService.params.batch_report,
            {
              deleted: false,
            },
            () => {
              return
            },
            () => {
              return
            },
            true,
          )
        batchObservationData.questions = await getQuestions(
          Api,
          stateService.params.batch_report,
          false,
        )
        const allQuestions = []
        const displayedQuestions = [...batchObservationData.questions]
        batchObservationData.observations_detail.forEach((el) =>
          allQuestions.push(...el.questions),
        )

        if (allQuestions.length > displayedQuestions?.length) {
          const displayedQuestionsId = displayedQuestions.map((el) => el.id)
          const deletedQuestions = allQuestions.filter(
            (el) => !displayedQuestionsId.includes(el),
          )

          if (deletedQuestions?.length) {
            const combinedReportAnswers = []
            for (let i = 0; i < deletedQuestions.length; i++) {
              await Api.Questions.byIDWithoutDeletedParam(
                deletedQuestions[i],
                (resp) => {
                  const answers = [
                    combinedReportAnswers.find(
                      (el) => el.question === deletedQuestions[i],
                    ) || {
                      answer: 'n/a',
                      question: deletedQuestions[i],
                      lat: null,
                      lon: null,
                      photos: [],
                    },
                  ]
                  const currentObservation =
                    batchObservationData.observations_detail.find((el) =>
                      el.questions.includes(deletedQuestions[i]),
                    )
                  const category = currentObservation?.category
                  const project_name = currentObservation?.project?.name

                  batchObservationData.questions.push({
                    ...resp,
                    answers,
                    category,
                    project_name,
                  })
                },
              )
            }

            batchObservationData.questions.sort(
              (a, b) =>
                allQuestions.findIndex((item) => item === a.id) -
                allQuestions.findIndex((item) => item === b.id),
            )
          }
        }

        const notes = []
        Object.values(batchObservationData.observations_detail).forEach(
          (observation) => {
            observation.observation_notes.forEach((note) => {
              if (!note.deleted) notes.push(note)
            })
          },
        )
        batchObservationData.observation_notes = notes

        const wildcards = []
        Object.values(batchObservationData.observations_detail).forEach(
          (observation) => {
            observation.observation_wildcards.forEach((wildcard) => {
              if (!wildcard.deleted) wildcards.push(wildcard)
            })
          },
        )
        batchObservationData.observation_wildcards = wildcards

        setFetchedData([
          batchObservationData,
          batchDataChange(batchObservationData),
        ])
      }
    }

    fetchData()
  }, [observationID])

  return fetchedData || null
}

function dataChange(questionsData, answersData) {
  answersData.sort(
    (a, b) =>
      questionsData.findIndex((item) => item.id === a.question) -
      questionsData.findIndex((item) => item.id === b.question),
  )

  const listsByOrder = {}

  const lists = {
    no: {},
    pr: {},
    pa: {},
    cls: {},
    yes: {},
    'n/a': {},
  }

  let answeredQuestionOrder = 1

  answersData.forEach((answer, idx) => {
    listsByOrder[idx + 1] = {
      questionId: answer.question,
      answer: answer,
      order: idx + 1,
    }
    if (answer.answer !== 'n/a') {
      listsByOrder[idx + 1].answeredFieldOrder = answeredQuestionOrder
      answeredQuestionOrder += 1
    }
    questionsData.forEach((question) => {
      if (listsByOrder[idx + 1].questionId === question.id) {
        listsByOrder[idx + 1].question = question
      }
    })
  })
  flatMap(listsByOrder).forEach((question) => {
    lists[question.answer.answer][question.order] = question
  })
  flatMap(lists['n/a']).forEach((answer) => {
    lists['n/a'][answer.order].answeredFieldOrder = answeredQuestionOrder
    answeredQuestionOrder += 1
  })

  return lists
}

async function getQuestions(Api: ApiService, uuid: string, shared: boolean) {
  let questions: ReportQuestion[] = []
  const { data: firstGet }: DataWrap<PaginatedGet<ReportQuestion[]>> =
    await Api.get(
      (shared ? 'shares/batch_report/' : 'batch_report/') + uuid + '/questions',
      {
        deleted: false,
      },
      () => {
        return
      },
      () => {
        return
      },
      true,
    )
  questions = [...firstGet.results, ...questions]
  const pageAmount: number = Math.ceil(firstGet.count / 20)
  for (let i = 2; i <= pageAmount; i++) {
    const { data: sequenceGet }: DataWrap<PaginatedGet<ReportQuestion[]>> =
      await Api.get(
        (shared ? 'shares/batch_report/' : 'batch_report/') +
          uuid +
          '/questions',
        {
          deleted: false,
          page: i,
        },
        () => {
          return
        },
        () => {
          return
        },
        true,
      )
    questions = [...questions, ...sequenceGet.results]
  }
  return questions
}

function batchDataChange(getData) {
  const lists = {
    no: {},
    pr: {},
    pa: {},
    cls: {},
    yes: {},
    'n/a': {},
  }

  let singleOrder = 1

  getData.questions.forEach((question) => {
    if (!question?.answers?.length) {
      lists['n/a'][singleOrder] = {
        question: question,
        order: singleOrder,
        answer: {
          answer: 'n/a',
          photos: [],
        },
      }
      singleOrder += 1
    } else {
      question?.answers?.forEach((answer) => {
        if (answer) {
          lists[answer?.answer][singleOrder] = {
            question: question,
            order: singleOrder,
            answer: answer,
          }
          singleOrder += 1
        }
      })
    }
  })

  let fieldsOrder = 1

  for (const key in lists) {
    Object.values(lists[key]).forEach((field) => {
      lists[key][field.order] = {
        ...field,
        answeredFieldOrder: fieldsOrder,
      }
      fieldsOrder += 1
    })
  }

  return lists
}
