import * as React from 'react'
import { css } from '@emotion/react'
import { parseDate } from '@/utils/parseDate'
import { fullUsername } from '@/utils/fullUsername'
import { ImageSection, deletedFieldsFiltering } from '../../../components'

import { ApplicationConfig, ReportField } from '@/react/types'
import { useAngularServices } from '@/react/components'

type Props = {
  questionFullData: ReportField
  app: ApplicationConfig
}

export function QuestionField({ questionFullData, app }: Props) {
  const { CurrentUser } = useAngularServices()

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const {
    question,
    answer: answerBeforeChanges,
    answeredFieldOrder,
  } = questionFullData
  const [answer, setAnswer] = React.useState({})

  React.useEffect(() => {
    deletedFieldsFiltering(answerBeforeChanges, setAnswer)
  }, [])

  if (!answer.answer) return null

  const severityDisplay = {
    1: app.observation_low_severity,
    2: app.observation_medium_severity,
    3: app.observation_high_severity,
  }

  const questionStyle = getQuestionStyle(variableColor)

  const questionHeader = (
    <h4
      className="question-header"
      dangerouslySetInnerHTML={{
        __html: question?.project_name
          ? `${answeredFieldOrder}. ${question?.project_name} (${question.category.name}) - ${question.name}`
          : `${answeredFieldOrder}. ${question.name}`,
      }}
    />
  )

  if (answer.answer === 'n/a')
    return <div css={questionStyle}>{questionHeader}</div>

  const severityLevelElements = {
    1: (
      <span className="severity-low severity-text">
        {severityDisplay[answer.severity || 1].toUpperCase()}
      </span>
    ),
    2: (
      <span className="severity-med severity-text">
        {severityDisplay[answer.severity || 1].toUpperCase()}
      </span>
    ),
    3: (
      <span className="severity-high severity-text">
        {severityDisplay[answer.severity || 1].toUpperCase()}
      </span>
    ),
  }

  return (
    <div css={questionStyle}>
      {questionHeader}
      <div className="sub-field severity-sub-field">
        {answer.answer !== 'n/a' && answer.answer !== 'yes' ? (
          <div>{severityLevelElements[answer.severity || 1]}</div>
        ) : null}
      </div>
      <div className="base-for-sub-field">
        {answer.notes.length > 0 ? (
          <div className="sub-field">
            <label className="sub-field-header">
              {app.observation_note_header}:
            </label>
            <div className="multiple-answer-body">
              {Object.values(answer.notes).map((note, idx) => (
                <div className="single-answer" key={idx}>
                  {' '}
                  <span className="variable-color-text">
                    {' ' +
                      parseDate(note.date_created) +
                      ' - ' +
                      fullUsername(
                        note.user ? note.user : note.external_contact,
                      ) +
                      ' - '}
                  </span>
                  <span className="view__text">{note.text}</span>
                </div>
              ))}
            </div>
            <hr />
          </div>
        ) : null}
        {answer.corrective_actions.length ? (
          <div className="sub-field">
            <label className="sub-field-header">
              {app.observation_corrective_action_header}:{' '}
            </label>
            <div className="multiple-answer-body">
              {Object.values(answer.corrective_actions).map(
                (correctiveAction, idx) => (
                  <div key={idx} className="single-answer">
                    <span className="variable-color-text">
                      {' ' +
                        parseDate(correctiveAction.date_created) +
                        ' - ' +
                        fullUsername(
                          correctiveAction.user
                            ? correctiveAction.user
                            : correctiveAction.external_contact,
                        ) +
                        ' - '}
                    </span>
                    <span className="view__text">{correctiveAction.text}</span>
                  </div>
                ),
              )}
            </div>
            <hr />
          </div>
        ) : null}
        <div className="sub-field-row">
          {answer.answer_party_observed_answer_list.length ? (
            <div className="sub-field left-field">
              <label className="sub-field-header">
                {app.observation_party_observed}
                {': '}
              </label>
              <div className="multiple-answer-body">
                {answer.answer_party_observed_answer_list.map((po) => {
                  if (!po.deleted) {
                    return (
                      <div className="single-answer">
                        <span> {po.party_observed.name}</span>
                      </div>
                    )
                  }
                })}
              </div>
              <hr />
            </div>
          ) : null}
          {answer.reference ? (
            <div className="sub-field">
              <label className="sub-field-header">
                {app.observation_reference}:
              </label>
              <span className="variable-color-text">
                {' '}
                {parseDate(answer.date_created)}{' '}
              </span>
              <span className="view__text">{answer.reference}</span>
              <hr />
            </div>
          ) : null}
        </div>
        {answer?.internal_assignees?.length ||
        answer?.external_assignees?.length ? (
          <div className="sub-field sub-field-with-multiples">
            <label>Assigned To: </label>
            <div className="multiple-answer-body">
              {answer.external_assignees.map((assignee, idx) => {
                return (
                  <div key={idx} className="single-answer">
                    <span key={idx} className="date">
                      {parseDate(assignee.date_created)}{' '}
                      {assignee.external_contact.first_name
                        ? assignee.external_contact.first_name + ' '
                        : null}
                      {assignee.external_contact.last_name
                        ? assignee.external_contact.last_name
                        : null}
                      {!assignee.external_contact.first_name &&
                      !assignee.external_contact.last_name &&
                      !assignee.external_contact.company_name
                        ? null
                        : '; '}
                      {assignee.external_contact.company_name}
                      {!assignee.external_contact.first_name &&
                      !assignee.external_contact.last_name &&
                      !assignee.external_contact.company_name
                        ? assignee.external_contact.email
                        : null}
                    </span>
                  </div>
                )
              })}
              {answer.internal_assignees.map((assignee, idx: number) => {
                return (
                  <div key={idx} className="single-answer">
                    <span key={idx} className="date">
                      {parseDate(assignee.date_created)}{' '}
                      {fullUsername(assignee.full_assignee)}
                      {'; '}
                      {assignee.full_assignee.company_name}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        ) : null}
      </div>
      {answer.photos[0] ? <ImageSection images={answer.photos} /> : null}
    </div>
  )
}

function getQuestionStyle(variableColor) {
  return css({
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    hr: {
      paddingTop: 4,
      paddingBottom: 5,
    },
    label: {
      margin: 0,
    },
    '.question-header': {
      display: 'inline',
      gap: '3px',
      margin: 0,
      paddingTop: 5,
      paddingBottom: 10,
      pageBreakInside: 'avoid',
      pageBreakBefore: 'auto',
      pageBreakAfter: 'auto',

      p: {
        display: 'inline',
      },

      a: {
        fontWeight: 'bold',
        textDecoration: 'underline',
      },
      breakInside: 'avoid',
      breakBefore: 'auto',
      breakAfter: 'auto',
    },
    '.question-name': {
      whiteSpace: 'pre',
    },
    '.sub-field-with-multiples': {
      label: {
        verticalAlign: 'top',
      },
    },
    '.view__text': {
      display: 'contents',
      whiteSpace: 'pre-wrap',
    },
    '.multiple-answer-body': {
      display: 'inline-block',
      width: '75%',
      paddingLeft: '5px',
      '.single-answer': {
        width: '100%',
      },
    },
    '.severity-sub-field': {
      fontWeight: 600,
    },
    '.base-for-sub-field': {
      paddingLeft: '30px',
      '@media (max-width: 650px)': {
        paddingLeft: 0,
      },
      '@media print': {
        paddingLeft: '30px',
      },
    },
    '.sub-field-header': {
      color: '#A9A9A9 !important',
      fontStyle: 'italic',
      verticalAlign: 'top',
    },
    '.variable-color-text': {
      fontSize: '12px',
      color: variableColor + ' !important',
    },
    '.severity-text': {
      paddingLeft: 0,
    },
    '.sub-field-row': {
      verticalAlign: 'top',
      pageBreakInside: 'avoid',
      pageBreakBefore: 'auto',
      pageBreakAfter: 'auto',
      breakInside: 'avoid',
      breakBefore: 'auto',
      breakAfter: 'auto',
      '.sub-field': {
        verticalAlign: 'top',
        width: '48%',
        display: 'inline-block',
        pageBreakInside: 'avoid',
        pageBreakBefore: 'auto',
        pageBreakAfter: 'auto',
        breakInside: 'avoid',
        breakBefore: 'auto',
        breakAfter: 'auto',
      },
      '.left-field': {
        marginRight: '4%',
      },
      '@media (max-width: 650px)': {
        '.sub-field': {
          width: '100%',
          pageBreakInside: 'avoid',
          pageBreakBefore: 'auto',
          pageBreakAfter: 'auto',
          breakInside: 'avoid',
          breakBefore: 'auto',
          breakAfter: 'auto',
        },
        '.left-field': {
          marginRight: 0,
        },
      },
      '.view__text': {
        display: 'contents',
        whiteSpace: 'pre-wrap',
      },
    },
  })
}
