import React, { useState } from 'react'
import { ButtonElement } from '@/react/components'
import closeIcon from '/src/assets/icons/X Gray.svg'
import { css } from '@emotion/react'
import { useEffect, useRef } from 'react'

export const ConfirmationModal = ({
  handleClick,
  action = '',
  handleClose,
  title = '',
}) => {
  const modalRef = useRef()
  const [isButtonDisabled, setButtonDisabled] = useState(false)

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      handleClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => (document.body.style.overflow = 'auto')
  }, [])

  return (
    <div
      css={baseStyle}
      className="modal__wrapper"
      ref={modalRef}
      onMouseOver={(e) => {
        e.stopPropagation()
      }}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <img
        src={closeIcon}
        alt="cross-icon"
        className="close__icon"
        onClick={() => {
          handleClose()
        }}
      />
      <div className="modal__info">
        <p className="modal__message">
          {action && <strong>{action}?</strong>}
          {action && <br />}
          <p className="message__name">{title}</p>
        </p>
        <ButtonElement
          text="OK"
          buttonType="submit"
          functionToTrigger={() => {
            setButtonDisabled(true)
            handleClick()
            handleClose()
          }}
          disabled={isButtonDisabled}
        />
      </div>
    </div>
  )
}

const baseStyle = css({
  position: 'absolute',
  backgroundColor: 'white',
  boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.18)',
  marginTop: '-27px',
  right: 0,
  top: '28px',
  zIndex: 1,
  '.close__icon': {
    position: 'absolute',
    right: '4px',
    top: '5px',
    width: '13px',
    height: '13px',
    cursor: 'pointer',
  },
  '.modal__message': {
    color: '#666',
    lineHeight: 'normal',
    letterSpacing: '-0.14px',
    marginTop: '10px',
    marginBottom: '10px',
    textAlign: 'center',
  },
  '.message__name': {
    width: '146px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: 14,
    margin: 0,
    maxHeight: '38px',
  },
  '.delete__title': {
    marginBottom: '14px',
  },
  '.delete__subtitle': {
    color: '#666',
    textAlign: 'center',
    fontSsize: '14px',
    margin: 0,
    marginBottom: '25px',
    width: '206px',
  },
  '.message__email': {
    margin: 0,
    fontWeight: 600,
  },
  '.modal__info': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '17px',
  },
})
