import * as React from 'react'
import {
  ButtonElement,
  UniversalAlert,
  useAngularServices,
} from '@/react/components'
import { css } from '@emotion/react'
import { useRouter } from '@/react/hooks'
import { getTranslatedString } from '@/utils'
import { ContactFields, dataGet, FieldSelector, sendDataLogic } from './index'
import { validateFields } from '@screens/MobileForms/CreateEditPages/components/sendDataLogic'
import { MobileFormsNavBar } from '../../commonComponents/MobileFormNavBar'

export const ReportBase = ({
  setLogoForExternals,
  setError,
}: {
  setLogoForExternals?: any
  setError?: any
}) => {
  const { Api, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()

  const [templateData, setTemplateData] = React.useState({})
  const [formMemory, setFormMemory] = React.useState({})
  const [oldFormMemory, setOldFormMemory] = React.useState({})
  const [popUps, setPopUps] = React.useState([])
  const [formFirstName, setFormFirstName] = React.useState('')
  const [formSecondName, setFormSecondName] = React.useState('')
  const [formEmail, setFormEmail] = React.useState('')
  const [formCompany, setFormCompany] = React.useState('')
  const [externalError, setExternalError] = React.useState('')
  const [promisesToDeleteFiles, setPromisesToDeleteFiles] = React.useState([])
  const [language, setLanguage] = React.useState('origin')
  const [firstFieldToError, setFirstFieldToError] = React.useState(null)
  const [disableButton, setDisableButton] = React.useState(false)
  const [inputIsEdited, setInputIsEdited] = React.useState(false)
  const [signatureMemory, setSignatureMemory] = React.useState([])
  const [sketchMemory, setSketchMemory] = React.useState([])

  const isExternalForm = stateService.current.name === 'assigned.external_form'
  const isInvalidField =
    isExternalForm &&
    !(formFirstName && formSecondName && formEmail && formCompany)

  function addPromisesToDeleteFiles(addedFile) {
    promisesToDeleteFiles.push(addedFile)
    setPromisesToDeleteFiles(promisesToDeleteFiles)
  }

  React.useEffect(() => {
    dataGet(
      Api,
      setTemplateData,
      stateService,
      setFormMemory,
      setOldFormMemory,
      setExternalError,
      setLogoForExternals,
      setError,
    )
  }, [])

  if (externalError !== '') {
    return (
      <div className="alert alert-danger text-center" ng-if="error">
        {externalError}
      </div>
    )
  }

  if (!templateData?.form?.fields || !Object.keys(formMemory).length)
    return null

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  if (variableColor) localStorage.setItem('variable-color', variableColor)

  const baseStyle = getBaseStyle(isExternalForm)
  let setTimeoutAmount = 0

  function addSketchOrSignatureText(value, signature, typingStart = false) {
    if (typingStart) {
      if (!inputIsEdited) {
        setInputIsEdited(true)
      }
    } else {
      if (signature) {
        let foundExistingPromises: number = 0
        signatureMemory.every((signature, idx) => {
          if (signature.id === value.id) {
            signatureMemory[idx] = value
            foundExistingPromises += 1
            return false
          } else return true
        })
        if (foundExistingPromises === 0) {
          signatureMemory.push(value)
        }
        setSignatureMemory(signatureMemory)
      } else {
        let foundExistingPromises: number = 0
        sketchMemory.every((sketch, idx) => {
          if (sketch.id === value.id) {
            sketchMemory[idx] = value
            foundExistingPromises += 1
            return false
          } else return true
        })
        if (foundExistingPromises === 0) {
          sketchMemory.push(value)
        }
        setSketchMemory(sketchMemory)
      }
      setTimeoutAmount += 1
      setTimeout(() => {
        setTimeoutAmount -= 1
        if (setTimeoutAmount === 0) {
          setInputIsEdited(false)
        }
      }, 2000)
    }
  }

  CurrentUser.setClientSettings(templateData.project.client.general_settings)

  function updateExternalClientData(firstName, secondName, company, email) {
    if (firstName !== null) setFormFirstName(firstName)
    if (secondName !== null) setFormSecondName(secondName)
    if (company !== null) setFormCompany(company)
    if (email !== null) setFormEmail(email)
    setDisableButton(false)
  }

  const saveAlertText = getTranslatedString(
    'Please answer all required fields before saving.',
  )

  const sendForm = async (asDraft) => {
    await sendDataLogic(
      sketchMemory,
      signatureMemory,
      formMemory,
      oldFormMemory,
      Api,
      stateService,
      templateData.user.id,
      templateData,
      {
        first_name: formFirstName,
        last_name: formSecondName,
        email: formEmail,
        company_name: formCompany,
      },
      promisesToDeleteFiles,
      asDraft,
    )
  }

  async function save(asDraft: boolean) {
    setDisableButton(true)
    if (asDraft) {
      await sendForm(asDraft)
    } else {
      const validation = validateFields(
        formMemory,
        setPopUps,
        <UniversalAlert
          text={saveAlertText}
          setAlert={setPopUps}
          submitButtonText={'OK'}
        />,
        setFirstFieldToError,
      )

      if (validation) {
        await sendForm(asDraft)
      } else {
        setDisableButton(false)
      }
    }
  }

  const locallySelectedLanguage = JSON.parse(
    localStorage.getItem('language-data'),
  ).selected

  if (locallySelectedLanguage !== language) {
    setLanguage(locallySelectedLanguage)
    return null
  }

  function onChange({
    id,
    possibleGroupData,
    value,
    fieldType = 'generic',
  }: {
    value: string
    possibleGroupData: boolean
    id: string
    fieldType?:
      | 'generic'
      | 'time'
      | 'date'
      | 'fileAdd'
      | 'photoAdd'
      | 'fileReplace'
      | 'photoReplace'
      | 'sketches'
      | 'signatures'
  }): void {
    setDisableButton(true)
    let obj = possibleGroupData
      ? formMemory[id][possibleGroupData]
      : formMemory[id]
    obj['touched'] = true
    if (
      !['fileAdd', 'photoAdd', 'fileReplace', 'photoReplace'].includes(
        fieldType,
      )
    ) {
      obj.localAnswer.value = value
      obj.localAnswer.text = value
    }
    switch (fieldType) {
      case 'date':
        obj.localAnswer.date = value
        break
      case 'time':
        obj.localAnswer.time = value
        break
      case 'sketches':
        obj.localAnswer.sketches.push(value)
        obj.localAnswer.value = ''
        break
      case 'signatures':
        obj.localAnswer.signatures.push(value)
        obj.localAnswer.value = ''
        break
      case 'photoAdd':
        obj.localAnswer.photos.push(value)
        obj.localAnswer.value = ''
        break
      case 'fileAdd':
        obj.localAnswer.files.push(value)
        obj.localAnswer.value = ''
        break
      case 'photoReplace':
        obj.localAnswer.photos = value
        obj.localAnswer.value = ''
        break
      case 'fileReplace':
        obj.localAnswer.files = value
        obj.localAnswer.value = ''
        break
    }
    if ('date_updated' in obj.localAnswer) delete obj.localAnswer.date_updated
    if ('date_created' in obj.localAnswer) delete obj.localAnswer.date_created
    if ('fieldmeta' in obj.localAnswer) delete obj.localAnswer.fieldmeta
    setFormMemory(formMemory)
    setTimeout(() => {
      setDisableButton(false)
    }, 2000)
  }

  return (
    <div>
      {!isExternalForm ? <MobileFormsNavBar /> : null}
      <section css={baseStyle}>
        <div className="headers">
          <div className="sub-header sub-header-first">
            {getTranslatedString(templateData.form.name)}
          </div>
          <div className="sub-header sub-header-second">
            {getTranslatedString(templateData.project.name)}
          </div>
        </div>
        {isExternalForm && (
          <ContactFields
            updateContact={updateExternalClientData}
            possibleStartingData={templateData.external_contacts}
          />
        )}
        {templateData?.form?.fields.map((field, idx) => {
          return (
            <FieldSelector
              key={idx}
              fieldData={field}
              formMemory={formMemory}
              setFormMemory={setFormMemory}
              addSketchOrSignatureText={addSketchOrSignatureText}
              promisesToDeleteFiles={addPromisesToDeleteFiles}
              firstFieldToError={firstFieldToError}
              onChange={onChange}
            />
          )
        })}
        <div className="button-row-holder">
          {isExternalForm ? (
            <ButtonElement
              text={getTranslatedString('Draft')}
              functionToTrigger={() => {
                save(true)
              }}
              width={'fit-content'}
              disabled={isInvalidField || disableButton || inputIsEdited}
            />
          ) : (
            <ButtonElement
              text={'Cancel'}
              width={'fit-content'}
              functionToTrigger={() => {
                stateService.go('app.forms.reports', {
                  pageNumber: stateService.params.previousPageNumber,
                  search: stateService.params.previousSearch,
                  order: stateService.params.previousOrder,
                  reverse: stateService.params.previousReverse,
                })
              }}
            />
          )}
          <ButtonElement
            text={getTranslatedString('Submit')}
            functionToTrigger={save}
            width={'fit-content'}
            disabled={isInvalidField || disableButton || inputIsEdited}
            debounceTimeout={1000}
            buttonType={'submit'}
            additionalStyles={css({
              marginLeft: 20,
              '@media(max-width: 325px)': {
                marginTop: 20,
                marginLeft: 0,
              },
            })}
          />
        </div>
        {popUps}
      </section>
    </div>
  )
}

function getBaseStyle(isDraft) {
  return css({
    width: '98%',
    minHeight: '200px',
    marginRight: '1%',
    backgroundColor: 'white !important',
    padding: '20px',
    '.label-holder': {
      display: 'flex',
    },
    '.headers': {
      paddingTop: 10,
      paddingBottom: 20,
      marginBottom: 20,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderBottom: 'solid 1px #EEEFF3',
      '.sub-header-first': {
        fontSize: 18,
        textAlign: 'center',
      },
      '.sub-header-second': {
        fontSize: 14,
        marginTop: 5,
      },
      '.sub-header': {
        fontWeight: 'lighter',
      },
    },
    '.button-row-holder': {
      width: '100%',
      display: 'flex',
      justifyContent: isDraft ? 'right' : 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      '@media(max-width: 325px)': {
        flexDirection: 'column',
      },
      button: {
        width: isDraft ? 'fit-content' : 'auto',
      },
    },
  })
}
