import pluralize from 'pluralize'

angular.module('ccs').service('CurrentUser', CurrentUser)

function CurrentUser(Auth, $log, VariableThemeColor) {
  let currentUserInstance = null
  let clientSettingsInstance = {}

  const DEF_GENERAL_SETTING = {
    id: 3,
    party_observed_language: 'Party Observed',
    projects_language: 'Projects',
    projects_number_language: 'Project Number',
    colors: {
      primary_color: 'orange',
      secondary_color: 'orange',
      tertiary_color: '#FFFFFF',
    },
    app_urls: {
      android_url:
        'https://play.google.com/store/apps/details?id=com.capitalconstructionsolutions.hse&hl=en_US&gl=US',
      ios_url: 'https://apps.apple.com/us/app/rtrs-safety/id1209181961',
    },
  }

  function reset() {
    currentUserInstance = {
      id: '',
      username: '',
      token: Auth.getCookieToken(),
      email: '',
      isSuperuser: '',
      photo: '',
    }
  }

  function getInstance() {
    if (!currentUserInstance) {
      $log.error('Error! CurrentUser instance is empty!')
    }
    return currentUserInstance
  }

  function setInstance(newUser) {
    if (newUser) {
      const newUserModified = newUser
      if (!newUserModified.client)
        newUserModified.client = {
          general_settings: DEF_GENERAL_SETTING,
        }
      newUserModified.client.general_settings.project_language =
        pluralize.singular(
          newUser.client.general_settings.projects_language ||
            DEF_GENERAL_SETTING.projects_language,
        )
      newUserModified.client.general_settings.web_primary_color =
        newUserModified.client.general_settings.colors.secondary_color ||
        DEF_GENERAL_SETTING.colors.secondary_color
      clientSettingsInstance =
        newUserModified.client.general_settings || DEF_GENERAL_SETTING
      currentUserInstance = newUserModified
    } else {
      $log.error('Error! Current user cannot be set!')
      currentUserInstance = {}
    }
  }

  function is(...roles) {
    if (currentUserInstance && currentUserInstance.role) {
      return roles.includes(currentUserInstance.role)
    } else {
      $log.error('Error! No currentUser.role is set!')
      return false
    }
  }

  function isReadOnly() {
    if (currentUserInstance) {
      return is('read_only')
    } else {
      return true // @TODO: check if this clause is ever called. If not - replace with is('read_only')
    }
  }

  function isClientOrAdmin() {
    return !isReadOnly() // @TODO: if isReadOnly is removed replace with !is('read_only') in templates
  }

  function getId() {
    if (currentUserInstance && currentUserInstance.id) {
      return currentUserInstance.id
    } else {
      $log.error('Error! No currentUser.id found!')
      return ''
    }
  }

  function getClientId() {
    if (currentUserInstance.client?.id) {
      return currentUserInstance.client.id
    } else {
      $log.error('Error! No currentUser.client.id found!')
      return null
    }
  }

  function getClientCompany() {
    if (currentUserInstance.client?.company) {
      return currentUserInstance.client.company
    } else {
      $log.error('Error! No currentUser.client.company found!')
      return {}
    }
  }

  function setClientCompany(newCompany) {
    if (currentUserInstance.client) {
      currentUserInstance.client.company = newCompany
    } else {
      $log.error('Error! Cannot set currentUser.client.company!')
    }
  }

  function getRole() {
    if (currentUserInstance && currentUserInstance.role) {
      return currentUserInstance.role
    } else {
      $log.error('Error! No currentUser.role found!')
      return ''
    }
  }

  function getCustomPermissions() {
    if (currentUserInstance && currentUserInstance.custom_permissions) {
      return currentUserInstance.custom_permissions || []
    } else {
      $log.error('Error! No currentUser.custom_permissions found!')
      return []
    }
  }

  function getToken() {
    if (currentUserInstance && currentUserInstance.token) {
      return currentUserInstance.token
    } else {
      $log.error('Error! No currentUser.token found!')
      return ''
    }
  }

  function setToken(newToken) {
    if (currentUserInstance) {
      currentUserInstance.token = newToken
    } else {
      $log.error('Error! Cannot set currentUser.token!')
    }
  }

  function getProcoreAccessToken() {
    if (currentUserInstance && currentUserInstance.procore_access_token) {
      return currentUserInstance.procore_access_token
    } else {
      $log.error('Error! No currentUser.procore_access_token found!')
      return ''
    }
  }

  function setProcoreAccessToken(newToken) {
    if (currentUserInstance) {
      currentUserInstance.procore_access_token = newToken
    } else {
      $log.error('Error! Cannot set currentUser.procore_access_token!')
    }
  }

  function getClientSettings() {
    if (clientSettingsInstance) {
      return clientSettingsInstance
    } else {
      $log.error('Error! No client_settings found!')
      return {}
    }
  }

  function setClientSettings(newSettings) {
    clientSettingsInstance = newSettings
    clientSettingsInstance.project_language = pluralize.singular(
      newSettings.projects_language,
    )
    clientSettingsInstance.web_primary_color =
      clientSettingsInstance.colors.secondary_color
    VariableThemeColor.mainColorSet(clientSettingsInstance.web_primary_color)
  }

  return {
    reset,
    is,
    isReadOnly,
    isClientOrAdmin,
    getInstance,
    setInstance,
    getId,
    getClientId,
    getClientCompany,
    setClientCompany,
    getClientSettings,
    setClientSettings,
    getRole,
    getCustomPermissions,
    getToken,
    setToken,
    getProcoreAccessToken,
    setProcoreAccessToken,
  }
}
