import {
  CommonPageHeader,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { AssessmentNavBar } from '../components/AssessmentNavBar'
import { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { ManageRow } from './components/ManageRow'

export const ManageAssessments = () => {
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('name')
  const [page, setPage] = useState(1)
  const [templatesList, setTemplatesList] = useState([])
  const [count, setCount] = useState(20)
  const [refresh, setRefresh] = useState(false)

  const { Api } = useAngularServices()

  const TABLE_HEADER_SETTINGS = [
    {
      className: 'project',
      name: 'Templates',
      type: 'name',
      filterHeader: true,
      additionalStyles: {
        textAlign: 'left',
        paddingLeft: '14px',
        maxWidth: '505px',
        minWidth: '505px',
        borderBottom: '2px solid #C6C6C6',
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
        },
      },
    },
    {
      className: 'templates',
      name: 'Scoring',
      type: 'templates',
      filterHeader: false,
      additionalStyles: {
        textAlign: 'center',
        width: '95px',
        minWidth: '95px',
        borderBottom: '2px solid #C6C6C6',
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
        },
      },
    },
    {
      className: 'templates',
      name: 'Active',
      type: 'templates',
      filterHeader: false,
      additionalStyles: {
        textAlign: 'center',
        width: '95px',
        minWidth: '95px',
        borderBottom: '2px solid #C6C6C6',
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
        },
      },
    },
    {
      className: 'templates',
      name: 'QR Code',
      type: 'templates',
      filterHeader: false,
      additionalStyles: {
        textAlign: 'center',
        width: '95px',
        minWidth: '95px',
        borderBottom: '2px solid #C6C6C6',
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
        },
      },
    },
    {
      className: 'templates',
      name: 'Delete',
      type: 'templates',
      filterHeader: false,
      additionalStyles: {
        textAlign: 'center',
        width: '95px',
        minWidth: '95px',
        borderBottom: '2px solid #C6C6C6',
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
        },
      },
    },
  ]

  useEffect(() => {
    const getTemplates = async () => {
      const { data } = await Api.get('assessment/templates', {
        page,
        search,
        order,
      })
      setTemplatesList(data?.results)
      setCount(data?.count)
      setRefresh(false)
    }

    getTemplates()
  }, [page, search, refresh, order])

  return (
    <div className="manage__assessments" css={baseStyle}>
      <CommonPageHeader headerText={'Assessments'} />
      <AssessmentNavBar />
      <section className="manage__section">
        <div className="search__wrapper">
          <Search
            searchCallback={setSearch}
            search={search}
            pageCallback={setPage}
          />
        </div>
        <table>
          <TableHeaders
            headers={TABLE_HEADER_SETTINGS}
            callback={setOrder}
            popUpLocation={false}
            updateUrl={false}
            order={order}
            setOrder={setOrder}
          />
          {templatesList.map((template, index) => (
            <ManageRow
              template={template}
              index={index}
              setRefresh={setRefresh}
              search={search}
            />
          ))}
        </table>
        <div className="switch__wrapper">
          <PageSwitcher
            pageLength={20}
            listCount={count}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </section>
    </div>
  )
}

const baseStyle = css({
  paddingBottom: '200px',
  '.manage__section': {
    background: '#fff',
    paddingInline: '20px',
    paddingBottom: '10px',
    width: '925px',
  },
  '.search__wrapper': {
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  '#search': {
    width: '264px',
  },
  '.switch__wrapper': {
    paddingBottom: '1px',
  },

  '@media(max-width: 1150px)': {
    '.manage__section': {
      width: '100%',
      overflowX: 'scroll',
    },
  },
})
