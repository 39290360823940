/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ReactWrapper } from '@components'

import { BulkUploadPage } from './BulkImport'
import { CreatePage, EditPage } from './CreateEdit'
import { DocumentationPage } from './Documentation'
import { TrainingProgramBase } from './TrainingProgram'
import { ViewQuiz, ViewContent, ViewTopic } from './TrainingProgramTopics'
import { ShareTrainingDocumentation } from './ShareTrainingDocumentation'
import { TrainingManage } from './TrainingManage'
import { TrainingTopics } from './TrainingTopic'
import { TrainingQuizCreateEdit } from './TrainingQuizCreateEdit'
import { TrainingAssign } from './TrainingAssign'
import { TrainingEmployeeList } from './TrainingEmployeeList'
import { AssignTrainingList } from './AssignTrainingList'
import { TrainingGroups } from './TrainingGroups'
import { TrainingGroupsEmployeeList } from './TrainingGroupsEmployeeList'
import { TrainingGroupsTrainingList } from './TrainingGroupsTrainingList'
import { TrainingTrainingsList } from './TrainingTrainingsList'
import { JobTrainingGroups } from './JobTrainingGroups'
import { TrainingAssignTrainingToEmployee } from './TrainingAssignTrainingToEmployee'

export const trainingBulkUploadPageComponent = ReactWrapper(
  BulkUploadPage,
  'trainingBulkUploadPage',
)
export const trainingCreatePageComponent = ReactWrapper(
  CreatePage,
  'trainingCreatePage',
)
export const trainingEditPageComponent = ReactWrapper(
  EditPage,
  'trainingEditPage',
)
export const trainingDocumentationPageComponent = ReactWrapper(
  DocumentationPage,
  'trainingDocumentationListPage',
)
export const trainingProgramPageComponent = ReactWrapper(
  TrainingProgramBase,
  'trainingProgramPageComponent',
)
export const viewTrainingTopicPageComponent = ReactWrapper(
  ViewTopic,
  'viewTrainingTopicPageComponent',
)
export const viewTrainingTopicContentPageComponent = ReactWrapper(
  ViewContent,
  'viewTrainingTopicContentPageComponent',
)
export const viewTrainingTopicQuizPageComponent = ReactWrapper(
  ViewQuiz,
  'viewTrainingTopicQuizPageComponent',
)
export const shareTrainingDocumentationPageComponent = ReactWrapper(
  ShareTrainingDocumentation,
  'shareTrainingDocumentationPageComponent',
)
export const trainingManageComponent = ReactWrapper(
  TrainingManage,
  'trainingManageComponent',
)
export const trainingTopicsComponent = ReactWrapper(
  TrainingTopics,
  'trainingTopicsComponent',
)
export const trainingQuizCreateEditComponent = ReactWrapper(
  TrainingQuizCreateEdit,
  'trainingQuizCreateEditComponent',
)
export const trainingAssignComponent = ReactWrapper(
  TrainingAssign,
  'trainingAssignComponent',
)
export const trainingEmployeeListComponent = ReactWrapper(
  TrainingEmployeeList,
  'trainingEmployeeListComponent',
)
export const trainingTrainingsListComponent = ReactWrapper(
  TrainingTrainingsList,
  'trainingTrainingsListComponent',
)
export const trainingAssignTrainingListComponent = ReactWrapper(
  AssignTrainingList,
  'trainingAssignTrainingListComponent',
)
export const trainingGroupsComponent = ReactWrapper(
  TrainingGroups,
  'trainingGroupsComponent',
)
export const trainingGroupsEmployeeListComponent = ReactWrapper(
  TrainingGroupsEmployeeList,
  'trainingGroupsEmployeeListComponent',
)
export const trainingGroupsTrainingListComponent = ReactWrapper(
  TrainingGroupsTrainingList,
  'trainingGroupsTrainingListComponent',
)
//new added
export const JobTrainingGroupsComponent = ReactWrapper(
  JobTrainingGroups,
  'jobTrainingGroupsComponent',
)
export const TrainingAssignTrainingToEmployeeComponent = ReactWrapper(
  TrainingAssignTrainingToEmployee,
  'trainingAssignTrainingToEmployeeComponent',
)
