import * as React from 'react'
import { css } from '@emotion/react'
import { parseDate } from '@/utils/parseDate'
import { fullUsername } from '@/utils/fullUsername'
import { ImageSection, deletedFieldsFiltering } from '../../../components'
import { ApplicationConfig, ReportField } from '@/react/types'
import { useAngularServices } from '@/react/components'

type Props = {
  questionFullData: ReportField
  app: ApplicationConfig
}

export function QuestionField({ questionFullData, app }: Props) {
  const {
    question,
    answer: answerBeforeChanges,
    answeredFieldOrder,
  } = questionFullData
  const [answer, setAnswer] = React.useState({})
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  React.useEffect(() => {
    deletedFieldsFiltering(answerBeforeChanges, setAnswer)
  }, [])

  if (!answer.answer) return null

  const questionStyle = getQuestionStyle(variableColor)

  const questionHeader = (
    <h4
      className="question-header"
      dangerouslySetInnerHTML={{
        __html: question.project_name
          ? `Q: ${question.project_name} (<span class="variable-color-text">${question.category.name}</span>) - ${question.name}`
          : `Q: ${question.name}`,
      }}
    />
  )

  const severityDisplay = {
    1: app.observation_low_severity,
    2: app.observation_medium_severity,
    3: app.observation_high_severity,
  }

  if (answer.answer === 'n/a')
    return (
      <div css={questionStyle}>
        <div className="observation-numbering severity-sub-field">
          <h3>
            <u>Question {answeredFieldOrder}</u>
          </h3>
        </div>
        <div className="sub-field">{questionHeader}</div>
      </div>
    )

  const severityLevelElements = {
    1: (
      <span className="severity-low severity-text">
        {severityDisplay[answer.severity || 1].toUpperCase()}
      </span>
    ),
    2: (
      <span className="severity-med severity-text">
        {severityDisplay[answer.severity || 1].toUpperCase()}
      </span>
    ),
    3: (
      <span className="severity-high severity-text">
        {severityDisplay[answer.severity || 1].toUpperCase()}
      </span>
    ),
  }

  return (
    <div css={questionStyle}>
      <div className="observation-numbering severity-sub-field">
        <h3>
          <u>Question {answeredFieldOrder}</u>
        </h3>
        {answer.answer !== 'n/a' && answer.answer !== 'yes' ? (
          <div className="severity-block">
            {severityLevelElements[answer.severity || 1]}
          </div>
        ) : null}
      </div>
      <div className="sub-field">{questionHeader}</div>
      <div className="base-for-sub-field">
        {answer.notes.length > 0 ? (
          <div className="sub-field-multi note">
            <div className="multiple-answer-body">
              {Object.values(answer.notes).map((note, idx) => (
                <div className="single-answer" key={idx}>
                  <label className="sub-field-header">
                    {app.observation_note_header}:
                  </label>{' '}
                  <span className="green-text">
                    {' ' +
                      parseDate(note.date_created) +
                      ' - ' +
                      fullUsername(
                        note.user ? note.user : note.external_contact,
                      ) +
                      ' - '}
                  </span>
                  <span className="view__text">{note.text}</span>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        {answer.corrective_actions.length ? (
          <div className="sub-field-multi corrective-action">
            <div className="multiple-answer-body">
              {Object.values(answer.corrective_actions).map(
                (correctiveAction, idx) => (
                  <div key={idx} className="single-answer">
                    <label className="sub-field-header">
                      {app.observation_corrective_action_header}:{' '}
                    </label>
                    <span className="green-text">
                      {' ' +
                        parseDate(correctiveAction.date_created) +
                        ' - ' +
                        fullUsername(
                          correctiveAction.user
                            ? correctiveAction.user
                            : correctiveAction.external_contact,
                        ) +
                        ' - '}
                    </span>
                    <span className="view__text">{correctiveAction.text}</span>
                  </div>
                ),
              )}
            </div>
          </div>
        ) : null}
        <div className="sub-field-row">
          {answer.answer_party_observed_answer_list.length ? (
            <div className="sub-field-multi party-observed left-field">
              <div className="multiple-answer-body">
                {answer.answer_party_observed_answer_list.map((po) => {
                  if (!po.deleted) {
                    return (
                      <div className="single-answer">
                        <label className="sub-field-header">
                          {app.observation_party_observed}
                          {': '}
                        </label>
                        <span className="view__text">
                          {' '}
                          {po.party_observed.name}
                        </span>
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          ) : null}
          {answer.reference ? (
            <div className="sub-field-multi observation-reference">
              <div className="multiple-answer-body">
                <div className="single-answer">
                  <label className="sub-field-header">
                    {app.observation_reference}:
                  </label>
                  <span className="green-text">
                    {' '}
                    {parseDate(answer.date_created)}{' '}
                  </span>
                  <span className="view__text">{answer.reference}</span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {answer?.internal_assignees?.length ||
      answer?.external_assignees?.length ? (
        <div className="sub-field-multi">
          <label>Assigned To: </label>
          <div className="multiple-answer-body">
            {answer.external_assignees.map((assignee, idx) => {
              return (
                <div key={idx} className="single-answer">
                  <span key={idx} className="date">
                    {parseDate(assignee.date_created)}{' '}
                    {assignee.external_contact.first_name
                      ? assignee.external_contact.first_name + ' '
                      : null}
                    {assignee.external_contact.last_name
                      ? assignee.external_contact.last_name
                      : null}
                    {!assignee.external_contact.first_name &&
                    !assignee.external_contact.last_name &&
                    !assignee.external_contact.company_name
                      ? null
                      : '; '}
                    {assignee.external_contact.company_name}
                    {!assignee.external_contact.first_name &&
                    !assignee.external_contact.last_name &&
                    !assignee.external_contact.company_name
                      ? assignee.external_contact.email
                      : null}
                  </span>
                </div>
              )
            })}
            {answer.internal_assignees.map((assignee, idx) => {
              return (
                <div key={idx} className="single-answer">
                  <span key={idx} className="date">
                    {parseDate(assignee.date_created)}{' '}
                    {fullUsername(assignee.full_assignee)}
                    {'; '}
                    {assignee.full_assignee.company_name}
                  </span>
                </div>
              )
            })}
          </div>
        </div>
      ) : null}
      {answer.photos[0] ? <ImageSection images={answer.photos} /> : null}
    </div>
  )
}
function getQuestionStyle(variableColor) {
  return css({
    '.question-header': {
      p: {
        display: 'inline',
      },
    },
    '.sub-field-multi': {
      paddingTop: 20,
      '.multiple-answer-body': {
        width: '100%',
        display: 'inline-block',
        paddingLeft: '5px',
        '.single-answer': {
          width: '100%',
          paddingBottom: 2,
          paddingTop: 5,
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
          borderBottomColor: '#eeeeee',
          label: {
            color: 'transparent !important',
          },
          ':first-child': {
            label: {
              color: 'black !important',
            },
          },
        },
      },
    },
    '.view__text': {
      display: 'contents',
      whiteSpace: 'pre-wrap',
    },
    '.severity-sub-field': {
      fontWeight: 600,
      paddingBottom: '10px',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: '#eeeeee',
      h3: {
        display: 'inline-block',
        width: 'calc(100% - 165px)',
      },
      '.severity-block': {
        textAlign: 'right',
        display: 'inline-block',
        width: '165px',
      },
    },
    '.sub-field-header': {
      verticalAlign: 'top',
    },
    '.severity-text': {
      paddingLeft: 0,
    },
    '.sub-field': {
      paddingTop: '10px',
      pageBreakInside: 'avoid',
      pageBreakBefore: 'auto',
      pageBreakAfter: 'auto',
      breakInside: 'avoid',
      breakBefore: 'auto',
      breakAfter: 'auto',
      label: {
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '#eeeeee',
        paddingBottom: '10px',
      },
    },
    '.sub-field-row': {
      verticalAlign: 'top',
      '.sub-field': {
        pageBreakInside: 'avoid',
        pageBreakBefore: 'auto',
        pageBreakAfter: 'auto',
        breakInside: 'avoid',
        breakBefore: 'auto',
        breakAfter: 'auto',
        verticalAlign: 'top',
        width: '48%',
        display: 'inline-block',
        '.green-text': {
          color: 'green !important',
        },
      },
      '.sub-field-multi': {
        verticalAlign: 'top',
        width: '48%',
        display: 'inline-block',
        '.green-text': {
          color: 'green !important',
        },
      },
      '.left-field': {
        marginRight: '4%',
      },
      '@media (max-width: 650px)': {
        '.sub-field': {
          width: '100%',
        },
        '.left-field': {
          marginRight: 0,
        },
      },
    },
    '.underlined-field': {
      span: {
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '#eeeeee',
        paddingBottom: '10px',
      },
    },
    '.variable-color-text': {
      color: variableColor,
    },
    paddingTop: '30px',
    paddingBottom: '20px',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: '#eeeeee',
  })
}
