import * as React from 'react'
import { css } from '@emotion/react'
import {
  CommonPageHeader,
  FloatingWindowHorizontalNavigation,
  useAngularServices,
} from '@/react/components'
import { useRouter } from '@/react/hooks'
import { getTimeCardNavigationData } from '@screens/Timecard/components/commonVariables'
import { ReloadableElements } from './components'
import { AnalyticsIcon } from '@/react/componentAssets/AnalyticsIcon'

export const Timesheet = () => {
  const { $rootScope, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const role = CurrentUser.getRole()

  if (role !== 'client_admin' && role !== 'supervisor') {
    stateService.go('index.dashboard', {})
    return
  }

  const timeCardCheck = $rootScope.mobile_apps.filter((obj) => {
    return obj.id === Number(stateService.params.app)
  })[0].has_timecard

  if (!timeCardCheck) {
    stateService.go('index.dashboard', {})
    return null
  }

  return (
    <section css={baseStyle}>
      <CommonPageHeader headerText={'Timesheet'} />
      <a
        href="https://rtrspro.knowledgetransfer.us/#/views/Timecard_module_Draft/TimecardMapDashboard?:iid=1&:original_view=y"
        className="header__icon"
        target="_blank"
      >
        <AnalyticsIcon color={variableColor} />
      </a>
      <FloatingWindowHorizontalNavigation
        navSettings={getTimeCardNavigationData('timesheet')}
      />
      <ReloadableElements />
      <div css={css({ height: 100 })} />
    </section>
  )
}

const baseStyle = css({
  h2: {
    position: 'relative',
  },
  '.header__icon': {
    position: 'absolute',
    left: '145px',
    top: '96px',
  },
})
