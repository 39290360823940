import pluralize from 'pluralize'

const appCache = {}

const appResolver = {
  /** @ngInject */
  app: function (Api, $stateParams, $q) {
    if (appCache[$stateParams.app]) {
      return appCache[$stateParams.app]
    }

    const deferred = $q.defer()

    Api.Apps.byID($stateParams.app, function (resp) {
      const app = {
        ...resp,
        observation: pluralize.singular(resp.observations_display),
        observations: resp.observations_display,
        project: pluralize.singular(resp.projects_display),
        projects: resp.projects_display,
        category: pluralize.singular(resp.observations_categories),
        categories: resp.observations_categories,
        toolbox: pluralize.singular(resp.toolbox_talks_display),
        toolboxes: resp.toolbox_talks_display,
        mobile_form: pluralize.singular(resp.app_mobile_form_display),
      }
      appCache[$stateParams.app] = app
      deferred.resolve(app)
    })

    return deferred.promise
  },
}

const appsResolver = {
  /** @ngInject */
  apps: function (Api, $rootScope, $q) {
    const deferred = $q.defer()

    Api.currentUser((resp) => {
      if (resp.role === 'admin') return
      // Get the user ID
      const clientID = resp.client.id

      function getAllApps(cb, applications, page) {
        applications = applications || []
        page = page || 1
        Api.Apps.get(
          {
            order: 'name',
            page: page,
          },
          (resp) => {
            applications = applications.concat(resp.results)
            if (resp.next) {
              return getAllApps(cb, applications, ++page)
            } else {
              return cb(applications)
            }
          },
        )
      }

      getAllApps(function (apps) {
        // Create a filter to restrict mobile apps to those allowed by the admin interface
        // for this client
        function appClientQuery(apps) {
          return {
            client: clientID,
            apps: apps.map((a) => a.id).join(),
            is_active: 'True',
          }
        }

        function getAllAppClients(cb, appClients, page) {
          appClients = appClients || []
          page = page || 1
          // Get relevant client app restrictions
          Api.AppClients.get(
            angular.extend(appClientQuery(apps), { page: page }),
            function (resp) {
              // Store results for processing
              appClients = appClients.concat(resp.results)
              if (resp.next) {
                return getAllAppClients(cb, appClients, ++page)
              } else {
                return cb(appClients)
              }
            },
          )
        }

        getAllAppClients(function (appClients) {
          // Filter the apps by matching the app id to any active client - app allowances
          const filteredApps = apps.filter(function (app) {
            return appClients.filter((ac) => ac.app == app.id).length > 0
          })
          // Store the results in the scope
          $rootScope.mobile_apps = filteredApps.map((app) => {
            return {
              ...app,
              observation: pluralize.singular(app.observations_display),
              observations: app.observations_display,
              project: pluralize.singular(app.projects_display),
              projects: app.projects_display,
              category: pluralize.singular(app.observations_categories),
              categories: app.observations_categories,
              toolbox: pluralize.singular(app.toolbox_talks_display),
              toolboxes: app.toolbox_talks_display,
              mobile_form: pluralize.singular(app.app_mobile_form_display),
            }
          })

          deferred.resolve($rootScope.mobile_apps)
        })
      })
    })

    return deferred.promise
  },
}

const writeOnlyViewResolver = {
  /** @ngInject */
  load: function ($q, CurrentUser) {
    const defer = $q.defer()

    if (CurrentUser.isReadOnly()) {
      defer.reject('permission_denied')
    } else {
      defer.resolve()
    }

    return defer.promise
  },
}

const adminOnlyViewResolver = {
  /** @ngInject */
  load: function ($q, $rootScope, $state) {
    const defer = $q.defer()
    const getRoleInterval = setInterval(function () {
      if ($rootScope.permissionViewAdministration) {
        clearInterval(getRoleInterval)
        defer.resolve()
      } else if (
        !$rootScope.permissionViewAdministration &&
        $rootScope.permissionViewAdministration !== undefined
      ) {
        clearInterval(getRoleInterval)
        defer.reject('permission_denied')
        setTimeout(function () {
          if ($rootScope.permissionViewRiskDashboard) {
            $state.go('index.dashboard')
          } else {
            $state.go('trainings.documentation')
          }
        }, 2000)
      }
    }, 100)

    setTimeout(function () {
      clearInterval(getRoleInterval)
      defer.reject('permission_denied')
    }, 60000)
    return defer.promise
  },
}

const fullAdminOnlyViewResolver = {
  /** @ngInject */
  load: function ($q, $rootScope, $state) {
    const defer = $q.defer()
    const getRoleInterval = setInterval(function () {
      if ($rootScope.permissionViewFullAdministration) {
        clearInterval(getRoleInterval)
        defer.resolve()
      } else if (
        !$rootScope.permissionViewFullAdministration &&
        $rootScope.permissionViewFullAdministration !== undefined
      ) {
        clearInterval(getRoleInterval)
        defer.reject('permission_denied')
        if ($rootScope.permissionViewRiskDashboard) {
          $state.go('index.dashboard')
        } else {
          $state.go('trainings.documentation')
        }
      }
    }, 100)

    setTimeout(function () {
      clearInterval(getRoleInterval)
      defer.reject('permission_denied')
    }, 60000)
    return defer.promise
  },
}

const riskDashboardPageAccessResolver = {
  /** @ngInject */
  load: function (Api, $q, $rootScope, $state, CurrentUser) {
    const defer = $q.defer()

    const riskDashboardInterval = setInterval(function () {
      if (CurrentUser.getRole() === 'admin') {
        defer.resolve()
        clearInterval(riskDashboardInterval)
        $state.go('true_admin.home', {}, { reload: true })
      }
      if (CurrentUser && CurrentUser.getRole() && $rootScope.clientData) {
        const userRole = CurrentUser.getRole()

        if ($rootScope.clientData.risk_dashboard_is_active === false) {
          clearInterval(riskDashboardInterval)
          defer.resolve()
          if (userRole === 'supervisor') {
            defer.resolve()
            if ($rootScope.mobile_apps) {
              $state.go(
                'app.company_news.list',
                { app: $rootScope.mobile_apps[0].id },
                { reload: true },
              )
            } else {
              Api.AppClients.get(
                {
                  client: CurrentUser.getClientId(),
                  is_active: 'True',
                  page: 1,
                },
                function (resp) {
                  $state.go(
                    'app.company_news.list',
                    { app: resp.results[0].app },
                    { reload: true },
                  )
                },
              )
            }
          }

          if (userRole === 'client_admin') {
            defer.resolve()
            $state.go('admin.projects.list', {}, { reload: true })
          }
        }

        if (userRole === 'client_user') {
          if ($rootScope.clientData.training_is_active === false) {
            clearInterval(riskDashboardInterval)
            defer.resolve()
            if ($rootScope.mobile_apps) {
              $state.go(
                'app.company_news.list',
                { app: $rootScope.mobile_apps[0].id },
                { reload: true },
              )
            } else {
              Api.AppClients.get(
                {
                  client: CurrentUser.getClientId(),
                  is_active: 'True',
                  page: 1,
                },
                function (resp) {
                  $state.go(
                    'app.company_news.list',
                    { app: resp.results[0].app },
                    { reload: true },
                  )
                },
              )
            }
          } else {
            clearInterval(riskDashboardInterval)
            defer.resolve()
            $state.go('trainings.documentation')
          }
        }

        if (
          userRole === 'trainee_manager' ||
          userRole === 'trainee' ||
          userRole === 'read_only'
        ) {
          defer.resolve()
          $state.go('trainings.documentation')
        }
      }
      defer.resolve()
    }, 100)

    setTimeout(function () {
      clearInterval(riskDashboardInterval)
      defer.reject('permission_denied')
    }, 60000)
    return defer.promise
  },
}

const trainingPageAccessResolver = {
  /** @ngInject */
  load: function (Api, $q, $rootScope, $state, CurrentUser, Notification) {
    const defer = $q.defer()

    const trainingInterval = setInterval(function () {
      if (
        CurrentUser &&
        CurrentUser.getRole() &&
        $rootScope.clientData &&
        $rootScope.clientData.training_is_active === false
      ) {
        const userRole = CurrentUser.getRole()
        clearInterval(trainingInterval)

        if (userRole === 'client_user') {
          defer.resolve()

          if ($rootScope.mobile_apps) {
            $state.go(
              'app.company_news.list',
              { app: $rootScope.mobile_apps[0].id },
              { reload: true },
            )
          } else {
            Api.AppClients.get(
              {
                client: CurrentUser.getClientId(),
                is_active: 'True',
                page: 1,
              },
              function (resp) {
                $state.go(
                  'app.company_news.list',
                  { app: resp.results[0].app },
                  { reload: true },
                )
              },
            )
          }
        }

        if (
          userRole === 'trainee_manager' ||
          userRole === 'trainee' ||
          userRole === 'read_only'
        ) {
          defer.resolve()
          setTimeout(function () {
            Notification.danger('Permission Denied', 10000)
          }, 1000)
        }
      }
      defer.resolve()
    }, 100)

    setTimeout(function () {
      clearInterval(trainingInterval)
      defer.reject('permission_denied')
    }, 60000)

    return defer.promise
  },
}

export const makeLoadResolver = function (permissionType) {
  return {
    /** @ngInject */
    load: function ($q, $rootScope, $state, CurrentUser) {
      const defer = $q.defer()
      const getRoleInterval = setInterval(function () {
        if (CurrentUser.getRole()) {
          clearInterval(getRoleInterval)
          if ($rootScope[permissionType]) {
            defer.resolve()
          } else {
            defer.reject('permission_denied')
            setTimeout(function () {
              if ($rootScope.permissionViewRiskDashboard) {
                $state.go('index.dashboard', {}, { reload: true })
              } else {
                $state.go('trainings.documentation', {}, { reload: true })
              }
            }, 2000)
          }
        }
      }, 100)

      setTimeout(function () {
        clearInterval(getRoleInterval)
        defer.reject('permission_denied')
      }, 60000)

      return defer.promise
    },
  }
}

const PreviousStateResolver = {
  /** @ngInject */
  PreviousState: function ($state) {
    return {
      Name: $state.current.name,
      Params: $state.params,
      URL: $state.href($state.current.name, $state.params),
    }
  },
}

const allowEditing = {
  editing: function () {
    return true
  },
}

const forbidEditing = {
  editing: function () {
    return false
  },
}

export const resolvers = {
  appResolver,
  appsResolver,
  writeOnlyViewResolver,
  adminOnlyViewResolver,
  fullAdminOnlyViewResolver,
  riskDashboardPageAccessResolver,
  trainingPageAccessResolver,
  PreviousStateResolver,
  allowEditing,
  forbidEditing,
}
