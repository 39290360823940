import { percentageShadeBlendColor } from '@/utils/colorAdjustment'

export const checkIndex = (
  options: { value: string; label: string }[],
  value: string,
): number => {
  let foundIndex = 0
  options.forEach((option, index) => {
    if (option.value === value) foundIndex = index
  })
  return foundIndex
}

export const dropdownOptionStyles = (
  variableColor,
  height = '38px',
  darkerTheme = false,
) => {
  return {
    option: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: state.isSelected
        ? percentageShadeBlendColor(0.1, variableColor) + ' !important'
        : state.isFocused
        ? percentageShadeBlendColor(0.4, variableColor) + ' !important'
        : checkIndex(state.options, state.value) % 2 === 0
        ? 'white !important'
        : '#F2F3F5 !important',
      border: '1px solid #E6E6E6',
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: darkerTheme ? 'rgba(255,255,255,0.25)' : '#FFFFFF',
      borderColor: darkerTheme ? 'rgba(255,255,255,0.25)' : '#e5e6e7',
    }),
    menuList: (baseStyles, state) => ({
      ...baseStyles,
      padding: 0,
    }),
    valueContainer: (baseStyles, state) => ({
      ...baseStyles,
      color: darkerTheme ? 'rgba(255,255,255,0.6)' : 'black',
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      textOverflow: 'hidden',
      whiteSpace: 'normal',
      paddingTop: '5px',
      paddingBottom: '5px',
      color: darkerTheme ? 'rgba(255,255,255,0.6)' : 'black',
    }),
    input: (baseStyles, state) => ({
      ...baseStyles,
      color: darkerTheme ? 'rgba(255,255,255,0.6)' : 'black',
      margin: '0px',
    }),
    indicatorSeparator: (state) => ({
      display: 'none',
    }),
    indicatorsContainer: (baseStyles, state) => ({
      ...baseStyles,
    }),
  }
}

export const dropdownFiledTheme = (theme, variableColor) => {
  return {
    ...theme,
    backgroundColor: '#FFFFFF',
    backgroundImage: 'none',
    borderColor: '#e5e6e7',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '0',
    fontSize: '14px',
    marginBottom: '10px',
    colors: {
      ...theme.colors,
      primary: percentageShadeBlendColor(0.2, variableColor),
    },
  }
}
