import * as React from 'react'
import { css } from '@emotion/react'
import { parseDate } from '@/utils/parseDate'
import { ReportPhoto, ReportTheme } from '@/react/types'
import { getBase64Image } from '@/utils/ImageAndPrintProcessing'
import { useAngularServices } from '@components/ReactAngularContext'

type Props = {
  photo: ReportPhoto
  photoIdx: number
  theme?: ReportTheme
  onlyImage?: boolean
}

export function ImageWithPreview({
  photo,
  theme = 1,
  photoIdx,
  onlyImage = false,
}: Props) {
  const [previewVisibility, setPreviewVisibility] =
    React.useState<boolean>(false)
  const [imageReplaced, setImageReplaced] = React.useState<boolean>(false)
  const [imageUrl, setImageUrl] = React.useState<string>(photo.image)

  const { Api } = useAngularServices()

  const letter =
    theme === 4
      ? String.fromCharCode('A'.charCodeAt(0) + photoIdx) + '. '
      : null

  const getNewImage = async () => {
    const image = await getBase64Image(Api, photo.id)
    setImageUrl(image)
    setImageReplaced(true)
  }

  React.useEffect(() => {
    if (!imageReplaced) {
      getNewImage()
    }
  })

  return (
    <div css={baseStyle} className="singleImage">
      <img
        className="image-small"
        src={imageUrl}
        alt={String(photo.id)}
        onClick={() => {
          setPreviewVisibility(true)
        }}
      />
      {onlyImage
        ? null
        : [
            <div key={'first element'}>
              {letter}
              <span className={theme ? 'green-date-text' : undefined}>
                {parseDate(photo.upload_date)}
              </span>
            </div>,
            <div key={'second element'}>
              {photo.user
                ? photo.user?.first_name
                : photo?.external_contact?.first_name}{' '}
              {photo.user
                ? photo.user?.last_name
                : photo?.external_contact?.last_name}
            </div>,
          ]}
      {previewVisibility ? (
        <div
          className="preview-base"
          onClick={() => {
            setPreviewVisibility(false)
          }}
        >
          <div className="preview-img-wrapper">
            <img
              className="preview-img"
              src={imageUrl}
              alt={String(photo.id)}
              id={String(photo.id)}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}

const baseStyle = css({
  paddingTop: '10px',
  width: '30%',
  paddingRight: '2%',
  display: 'inline-block',
  pageBreakInside: 'avoid',
  pageBreakBefore: 'auto',
  pageBreakAfter: 'auto',
  '.image-small': {
    width: 'auto',
    maxHeight: '200px',
    cursor: 'pointer',
    maxWidth: '100%',
    pageBreakInside: 'avoid',
    pageBreakBefore: 'auto',
    pageBreakAfter: 'auto',
    breakInside: 'avoid',
    breakBefore: 'auto',
    breakAfter: 'auto',
  },
  '.green-date-text': {
    color: '#4ca990 !important',
  },
  '.preview-base': {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: '0',
    top: '0',
    width: '100vw',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 90,
    '.preview-img-wrapper': {
      width: '660px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '90vh',
      maxWidth: '90vw',
    },
    '.preview-img': {
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
      height: 'auto',
      border: '30px solid #fff',
    },
  },
  '@media (max-width: 650px)': {
    marginRight: '5%',
    width: '40%',
  },
  '@media print': {
    width: '30%',
    marginRight: '2%',
  },
})
