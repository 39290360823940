import * as React from 'react'
import { css } from '@emotion/react'

import { QuestionField } from './componets'
import { sortByAnswer } from '@/utils/data/SortByAnswer'

const questionField = (app) => (item) =>
  <QuestionField key={item?.question?.id} questionFullData={item} app={app} />

export function QuestionsList({ lists, app, viewNaQuestions }: any) {
  return (
    <section css={questionListBaseStyle}>
      {lists['no'].length > 0 ? (
        <div className="no">
          <h3 className="header-for-answer-type">
            FOLLOW UP REQUIRED ({lists['no'].length})
          </h3>
          {sortByAnswer(lists['no']).map(questionField(app))}
        </div>
      ) : null}
      {lists['pr'].length > 0 ? (
        <div className="pr">
          <h3 className="header-for-answer-type">
            PENDING REVIEW ({lists['pr'].length})
          </h3>
          {sortByAnswer(lists['pr']).map(questionField(app))}
        </div>
      ) : null}
      {lists['pa'].length > 0 ? (
        <div className="pa">
          <h3 className="header-for-answer-type">
            PENDING APPROVAL ({lists['pa'].length})
          </h3>
          {sortByAnswer(lists['pa']).map(questionField(app))}
        </div>
      ) : null}
      {lists['cls'].length > 0 ? (
        <div className="cls">
          <h3 className="header-for-answer-type">
            ISSUE RESOLVED ({lists['cls'].length})
          </h3>
          {sortByAnswer(lists['cls']).map(questionField(app))}
        </div>
      ) : null}
      {lists['yes'].length > 0 ? (
        <div className="yes">
          <h3 className="header-for-answer-type">
            YES ({lists['yes'].length})
          </h3>
          {sortByAnswer(lists['yes']).map(questionField(app))}
        </div>
      ) : null}
      {lists['n/a'][0] && viewNaQuestions ? (
        <div className="n/a">
          <h3 className="header-for-answer-type">
            N/A ({lists['n/a'].length})
          </h3>
          {sortByAnswer(lists['n/a']).map(questionField(app))}
        </div>
      ) : null}
    </section>
  )
}

const questionListBaseStyle = css({
  width: '100%',
  '.header-for-answer-type': {
    paddingBottom: 10,
    width: '100%',
    margin: 0,
    padding: '5px',
    textAlign: 'center',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: '#eeeeee',
    pageBreakInside: 'avoid',
    pageBreakBefore: 'auto',
    pageBreakAfter: 'auto',
    breakInside: 'avoid',
    breakBefore: 'auto',
    breakAfter: 'auto',
  },
  '.no': {
    '.header-for-answer-type': {
      color: 'red !important',
    },
    '.question-header': {
      color: 'red !important',
      pageBreakInside: 'avoid',
      pageBreakBefore: 'auto',
      pageBreakAfter: 'auto',
      breakInside: 'avoid',
      breakBefore: 'auto',
      breakAfter: 'auto',
    },
  },
  '.pr': {
    '.header-for-answer-type': {
      color: 'orange !important',
    },
    '.question-header': {
      color: 'orange !important',
      pageBreakInside: 'avoid',
      pageBreakBefore: 'auto',
      pageBreakAfter: 'auto',
      breakInside: 'avoid',
      breakBefore: 'auto',
      breakAfter: 'auto',
    },
  },
  '.pa': {
    '.header-for-answer-type': {
      color: '#ffdb57 !important',
    },
    '.question-header': {
      color: '#ffdb57 !important',
      pageBreakInside: 'avoid',
      pageBreakBefore: 'auto',
      pageBreakAfter: 'auto',
      breakInside: 'avoid',
      breakBefore: 'auto',
      breakAfter: 'auto',
    },
  },
  '.cls': {
    '.header-for-answer-type': {
      color: 'blue !important',
    },
    '.question-header': {
      color: 'blue !important',
      pageBreakInside: 'avoid',
      pageBreakBefore: 'auto',
      pageBreakAfter: 'auto',
      breakInside: 'avoid',
      breakBefore: 'auto',
      breakAfter: 'auto',
    },
  },
  '.yes': {
    '.header-for-answer-type': {
      color: 'green !important',
    },
    '.question-header': {
      color: 'green !important',
      pageBreakInside: 'avoid',
      pageBreakBefore: 'auto',
      pageBreakAfter: 'auto',
      breakInside: 'avoid',
      breakBefore: 'auto',
      breakAfter: 'auto',
    },
  },
})
